const commonTheme = theme => ({
  cardContainer: {
    minHeight: '120px',
    borderRadius: '0.35em',
    // STANDARDIZED ACROSS CARDS - DO NOT EDIT: controls the shadow of every card
    filter: 'drop-shadow(1px 1px 0.4rem rgba(0,0,0,0.07))',
    // STANDARDIZED ACROSS CARDS - DO NOT EDIT: controls the inner padding of every card
    padding: '1em 1em 1em 1em',
    // STANDARDIZED ACROSS CARDS - DO NOT EDIT: controls the top margin of every card
    marginTop: '1em',
    backgroundColor: 'white',
    flexGrow: 1,
  },
  textDefault: {
    paddingTop: '0.6em',
    fontFamily: 'Lato',
    fontSize: '1.1em',
    paddingBottom: '0.5em',
  },
  textUppercase: {
    fontFamily: 'Lato',
    fontSize: '1em',
    textTransform: 'uppercase',
  },
  textBold: {
    fontWeight: 'bold',
  },
  modalContainer: {
    // STANDARDIZED ACROSS MODALS - DO NOT EDIT: controls the shadow of every modal
    filter: 'drop-shadow(1px 1px 0.4rem rgba(0,0,0,0.07))',
    // STANDARDIZED ACROSS MODALS - DO NOT EDIT: controls the inner padding of every modal
    padding: '1em 2em 1em 2em',
  },
  modalContainerTransactions: {
    // STANDARDIZED ACROSS MODALS - DO NOT EDIT: controls the shadow of every modal
    filter: 'drop-shadow(1px 1px 0.4rem rgba(0,0,0,0.07))',
    // STANDARDIZED ACROSS MODALS - DO NOT EDIT: controls the inner padding of every modal
    padding: '1em 1em 1em 1em',
  },
  iconSmall: {
    position: 'relative',
    height: '0.5em',
    width: '0.5em',
    marginRight: '5px',
  },
  iconMedium: {
    fontSize: '1.2em',
    color: theme.palette.inactive,
  },
  divider: {
    width: '100%',
    padding: 0,
    margin: '0.5em 0 1.5em 0',
  },
});

export default commonTheme;
