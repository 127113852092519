import { parseAsync } from 'json2csv';
import MomentUtils from '@date-io/moment';
import { toast } from 'react-toastify';

const moment = new MomentUtils({ locale: 'fr' });
const toastOptions = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

export const exportCsv = async (dataCsv, name, opts, intl) => {
  if (dataCsv && dataCsv.length) {
    parseAsync(dataCsv, opts)
      .then(csv => {
        const encodedUri = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('target', '_blank');
        link.setAttribute('download', `${name}_${moment.format(moment.date(), 'keyboardDate')}.csv`);
        document.body.appendChild(link);
        link.click();
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      })
      .catch(err => console.error(err));
  }
};

export const download = async (url, name, intl) => {
  if (url) {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('download', `${name}_${moment.format(moment.date(), 'keyboardDate')}.xml`);
    document.body.appendChild(link);
    link.click();
    toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
  }
};

export const showDueAt = (row, format = 'fullDateTime24h') => {
  if (row?.type === 'debt' && row?.createdAt) return moment.format(moment.date(row?.createdAt), format);
  if (row?.dueAt) return moment.format(moment.date(row?.dueAt), format);
  return moment.format(moment.date(row?.createdAt), format);
};

export const showPaidAt = (row, format = 'fullDateTime24h') => {
  if (row?.type === 'debt' && row?.paidAt) return moment.format(moment.date(row?.paidAt), format);
  if (row?.paidAt) return moment.format(moment.date(row?.paidAt), format);
  return '';
};

export const formatDate = date => {
  return date ? moment.format(moment.date(date), 'fullDateTime24h') : '';
};
export const getDateHour = date => {
  return date ? moment.date(date).format('HH:mm') : '';
};
