import React, { useEffect } from 'react';

const JOE_APP_STORE_URL = 'https://apps.apple.com/fr/app/id1522534449';
const JOE_PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=co.getjoe.joe';
const JOE_WEBSITE_URL = 'https://django.eu/django-later';

const GetApp = () => {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (iosPlatforms.indexOf(platform) !== -1) {
      window.location.href = JOE_APP_STORE_URL;
    } else if (userAgent.match(/Android/)) {
      window.location.href = JOE_PLAY_STORE_URL;
    } else window.location.href = JOE_WEBSITE_URL;
  });

  return <></>;
};

export default GetApp;
