import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Mutation } from '@apollo/client/react/components';
import { FormattedMessage, injectIntl } from 'react-intl';
import Joi from 'joi-browser';

import { toast } from 'react-toastify';

import {
  InputAdornment,
  IconButton,
  Paper,
  Grid,
  TextField,
  Typography,
  FormControl,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import notchedOutline from '@material-ui/core/OutlinedInput';
import withStyles from '@material-ui/core/styles/withStyles';
import djangoLogo from '../../images/djangoLogo.png';

// Custom component imports
import StandardButton from '../../components/common/StandardButton';
import BlockedSignin from './BlockedSignin';

import messages from '../../i18n/Messages';
import loginGQL from './signIn.gql';

import { schemaSignIn } from '../../components/utils/joiValidationSchemas';
import UserLoggedInContext from '../UserLoggedInContext';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: '14vw',
    marginRight: '14vw',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginLeft: '0vw',
      marginRight: '0vw',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: '100vh',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.down('xs')]: {},
  },

  logoContainer: {
    height: '33%',
    width: '33%',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  logo: {
    maxHeight: '120%',
    maxWidth: '70%',
    paddingBottom: '1em',
    marginTop: '3.5em',
    marginBottom: '4em',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      paddingBottom: '2em',
      marginTop: '1em',
      marginBottom: '2em',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '66%',
      marginTop: '1em',
      marginBottom: '0em',
    },
  },
  link: {
    fontFamily: 'Lato',
    color: theme.palette.inactive,
    textDecoration: 'underline',
  },
  linkCreate: {
    textDecoration: 'none',
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  linkCreateText: {
    color: theme.palette.standardTextColorNoBackground,
  },
  signInTextOne: {
    paddingTop: '0.8em',
    fontWeight: 500,
    marginTop: '0.1em',
    paddingBottom: '1em',
    paddingRight: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3em',
    },
  },
  signInTextTwo: {
    paddingTop: '0.8em',
    marginTop: '0.1em',
    paddingBottom: '1em',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8em',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4em',
    },
  },
  margin: {
    marginTop: '0.8em',
  },
  marginTopInput: {
    borderRadius: theme.custom.borderRadius,
    margin: '0 auto',
    marginTop: '0.3em',
    marginBottom: '1em',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  marginBottomInput: {
    borderRadius: theme.custom.borderRadius,
    margin: '0 auto',
    marginBottom: '1em',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cssLabel: {
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  notchedOutline: {},
  paperWrapper: {
    boxShadow: theme.custom.standardShadow,
    width: '33%',
    minWidth: '450px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '50%',
      minWidth: '400px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      boxShadow: 'unset',
      minWidth: 'unset',
    },
    '&::before': {
      background: theme.palette.secondaryGradient,
      borderRadius: theme.custom.borderRadius,
      content: "''",
      zIndex: -100,
      position: 'absolute',
      top: '-5%',
      height: '90%',
      width: '200%',
      left: '-50%',
      opacity: '0.20',
      [theme.breakpoints.down('sm')]: {
        width: '150vw',
      },
      [theme.breakpoints.down('xs')]: {
        opacity: 0,
      },
    },
  },
  buttons: {
    paddingTop: '1em',
    paddingBottom: '0.5em',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bottomMessage: {
    paddingBottom: '2em',
    textAlign: 'center',
    margin: '0 auto',
  },
  agreementLink: {
    textDecoration: 'none',
    color: theme.palette.inactive,
    fontFamily: 'Lato',
    fontSize: '1em',
    opacity: '0.9',
    lineHeight: 1.25,
  },
  iconColorActive: {
    color: theme.palette.lightBlue,
  },
  iconColorInactive: {
    color: theme.palette.inactive,
  },
});

const SignIn = ({ children, ..._props }) => {
  const props = {
    blocked: false,
    wrongBrowser: false,
    ..._props,
  };
  const [state, setState] = useState({
    phone: '',
    password: '',
    redirect: false,
    revealPassword: false,
    openBlocked: false,
    errors: {},
  });

  const handleChange = ({ currentTarget: input }, schemaStep) => {
    const { errors } = state;
    const errorMessage = validateProperty(input, schemaStep);

    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const { name, value } = input;
    setState(prev => ({ ...prev, [name]: value }));
  };

  // This function checks a specific input of the form against the Joi schema
  const validateProperty = ({ name, value }, schemaStep) => {
    const obj = { [name]: value };
    const schema = { [name]: schemaStep[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  // Clear the form and errors
  const clear = () => setState(prev => ({ ...prev, phone: '', password: '' }));

  const handleShowPassword = () => {
    const { revealPassword } = state;
    setState(prev => ({ ...prev, revealPassword: !revealPassword }));
  };
  const [isLoggedIn, setIsLoggedIn] = useContext(UserLoggedInContext);
  useEffect(() => {
    const { history } = props;
    if (isLoggedIn) {
      history.push('/dashboard');
    }
  }, [isLoggedIn]);

  const handleLogin = data => {
    const { history } = props;
    setIsLoggedIn(true);
    history.push('/dashboard');
  };

  const forceLower = () => {
    const elt = document.getElementById('emailAddress');
    elt.value = elt.value.toLowerCase();
  };

  const {
    classes: {
      main,
      paper,
      logo,
      logoContainer,
      signInTextOne,
      signInTextTwo,
      marginTopInput,
      marginBottomInput,
      cssLabel,
      cssFocused,
      cssOutlinedInput,
      notchedOutline,
      paperWrapper,
      buttons,
      iconColorActive,
      iconColorInactive,
    },
    intl,
    blocked,
    wrongBrowser,
  } = props;

  const { errors, phone, password, redirect, revealPassword, openBlocked } = state;

  if (redirect) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <main className={main}>
        <Grid className={paper} item xs={12}>
          <div className={logoContainer}>
            <img src={djangoLogo} className={logo} alt="Django Logo" />
          </div>
          <Mutation
            mutation={loginGQL}
            variables={{ phone, password }}
            onCompleted={data => handleLogin(data)}
            onError={error => {
              console.error(error);
              if (error && error.networkError) {
                toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                });
              } else {
                toast.error(
                  intl.formatMessage({
                    id: 'components.utils.errorHasOccured',
                  }) + error.message,
                  {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  },
                );
              }
              return '';
            }}
          >
            {login => (
              <Paper elevation={0} className={paperWrapper}>
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="h1" className={signInTextOne}>
                    <FormattedMessage {...messages.signInTop_1} />
                  </Typography>
                  <Typography variant="h1" className={signInTextTwo}>
                    <FormattedMessage {...messages.signInTop_2} />
                  </Typography>
                </Grid>
                <Grid container justifyContent="center" alignItems="center" item xs={11} sm={9}>
                  <form>
                    <Grid>
                      <FormControl required fullWidth>
                        <TextField
                          error={!!errors.phone}
                          onKeyUp={forceLower}
                          helperText={
                            errors.phone ? <FormattedMessage id="components.utils.emailError" /> : null
                          }
                          name="phone"
                          className={marginTopInput}
                          InputLabelProps={{
                            classes: {
                              root: cssLabel,
                              focused: cssFocused,
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: cssOutlinedInput,
                              focused: cssFocused,
                              notchedOutline,
                            },
                          }}
                          label={<FormattedMessage {...messages.emailAddress} />}
                          variant="outlined"
                          id="emailAddress"
                          onChange={e => handleChange(e, schemaSignIn)}
                        />
                      </FormControl>

                      <FormControl required fullWidth>
                        <TextField
                          error={!!errors.password}
                          helperText={
                            errors.password ? <FormattedMessage id="components.utils.passwordError" /> : null
                          }
                          type={revealPassword ? 'text' : 'password'}
                          name="password"
                          className={marginBottomInput}
                          InputLabelProps={{
                            classes: {
                              root: cssLabel,
                              focused: cssFocused,
                            },
                          }}
                          InputProps={{
                            classes: {
                              root: cssOutlinedInput,
                              focused: cssFocused,
                              notchedOutline,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="Toggle password visibility"
                                  onClick={handleShowPassword}
                                  className={revealPassword ? iconColorActive : iconColorInactive}
                                >
                                  {revealPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          label={<FormattedMessage {...messages.password} />}
                          variant="outlined"
                          id="custom-css-outlined-input"
                          onChange={e => handleChange(e, schemaSignIn)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="baseline"
                      className={buttons}
                    >
                      <StandardButton
                        customComponentStyling={{
                          marginBottom: '0.5em',
                          height: '58px',
                          width: '100%',
                        }}
                        onClick={() => login()}
                      >
                        <Typography variant="button">
                          <FormattedMessage {...messages.signIn} />
                        </Typography>
                      </StandardButton>
                    </Grid>
                  </form>
                </Grid>
              </Paper>
            )}
          </Mutation>
        </Grid>
      </main>
      <BlockedSignin open={openBlocked} blocked={blocked} wrongBrowser={wrongBrowser} />
    </>
  );
};

export default injectIntl(withStyles(styles)(SignIn));
