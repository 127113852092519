import { LOAN_TYPE } from './LOAN_TYPE';

export const formatLoans = (loans = []) => {
  const formatLoanTypeText = loanType => {
    switch (loanType) {
      case LOAN_TYPE.backInTime:
      case LOAN_TYPE.magicx3:
        return 'Django Later';
      case LOAN_TYPE.shoppingx3:
        return 'Virtual Card';
      case LOAN_TYPE.django_free:
        return 'Django Free';
      default:
        return 'N/A';
    }
  };
  return loans.map(loan => {
    const transactionDate = loan.loanType === LOAN_TYPE.shoppingx3 ? loan.startedAt : loan.vdate;
    const transactionAmount = loan.loanType === LOAN_TYPE.shoppingx3 ? loan.amount : loan.value;
    const formattedLoan = {
      ...loan,
      transactionDate: transactionDate,
      username: `${loan.firstname} ${loan.lastname}`,
      transactionAmount,
      amountToBePaid: loan.amount?.toString(),
      feeAmount: loan.totalFeeAmount?.toString(),
      deliveryOption: loan.deliveryOption?.toString(),
      loanType: formatLoanTypeText(loan.loanType),
    };
    return formattedLoan;
  });
};
