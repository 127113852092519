import React from 'react';
import { Radio, FormControlLabel, FormControl, FormLabel, Grid } from '@material-ui/core';

const RadioButton = ({ label, value, checked, onChange, align, ...props }) => {
  return (
    <FormControlLabel
      control={
        <Radio
          style={{ color: '#FF6B4F', transform: 'scale(1.2)', opacity: checked ? 1 : 0.3 }}
          checked={checked}
          onChange={onChange}
          value={value}
          {...props}
        />
      }
      label={label}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: align === 'row' ? '362px' : '100%',
        height: '52px',
        borderWidth: '1px',
        borderRadius: '16px',
        color: '#040620',
        borderColor: '#E6E6E9',
        borderStyle: 'solid',
        padding: '16px',
      }}
      labelPlacement="start"
    />
  );
};

const RadioButtonGroup = ({ label, sublabel, options, selectedValue, onChange, align = 'row', ...props }) => {
  return (
    <FormControl component="fieldset" style={{ width: '750px', marginRight: '32px', paddingTop: '24px' }}>
      {label && (
        <FormLabel
          component="legend"
          style={{
            fontFamily: 'Lato',
            fontSize: '32px',
            fontWeight: 800,
            color: '#081433',
            lineHeight: '38.4px',
            textAlign: 'center',
            marginBottom: '16px',
            paddingTop: '14px',
          }}
          {...props}
        >
          {label}
        </FormLabel>
      )}
      {sublabel && (
        <FormLabel
          component="legend"
          style={{
            fontFamily: 'Poppins',
            fontSize: '15px',
            fontWeight: 600,
            color: '#081433',
            lineHeight: '22.5px',
            textAlign: 'left',
            marginBottom: '16px',
            marginLeft: '16px',
            paddingTop: '14px',
          }}
          {...props}
        >
          {sublabel}
        </FormLabel>
      )}
      <Grid
        container
        direction={align}
        spacing={2}
        style={{ display: 'flex', justifyContent: 'space-evenly' }}
      >
        {options.map((option, index) => (
          <Grid item key={option.value}>
            <RadioButton
              label={option.label}
              value={option.value}
              checked={selectedValue === option.value}
              onChange={onChange}
              align={align}
            />
          </Grid>
        ))}
      </Grid>
    </FormControl>
  );
};

export { RadioButton, RadioButtonGroup };
