import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';

// Material UI imports
import {
  Drawer,
  Hidden,
  ListItemIcon,
  ListItemText,
  Grid,
  Typography,
  ListItem,
  MenuItem,
  MenuList,
  IconButton,
  Toolbar,
} from '@material-ui/core';

import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import { AccountCircleOutlined as PersonIcon, ExitToApp as ExitIcon } from '@material-ui/icons';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import PaymentIcon from '@material-ui/icons/Payment';
import { withStyles } from '@material-ui/core/styles';

import { NavLink } from 'react-router-dom';

// i18n imports
import { FormattedMessage } from 'react-intl';
import messages from '../../i18n/Messages';

import djangoLogo from '../../images/djangoLogo.png';

import LOGOUT from '../../components/graphQL/mutations/auth/logout.gql';

const drawerWidth = 240;

const StyledListItem = withStyles(theme => ({
  root: {
    textDecoration: 'none',
    '&$selected': {
      position: 'relative',
      backgroundColor: `${theme.palette.darkBlue} !important`,
      overflow: 'visible',
      '&& div': {
        color: 'white',
        fill: 'white',
      },
      '&& span': {
        color: 'white',
      },
      '&::before': {
        content: "''",
        position: 'absolute',
        height: '100%',
        width: '6px',
        opacity: '0.4',
        background: `${theme.palette.darkBlue}`,
        right: '-6px',
        top: '0px',
        zIndex: '99',
      },
    },
    '&::before': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: '6px',
      opacity: '0.4',
      background: `${theme.palette.darkBlue}`,
      right: '-6px',
      top: '0px',
      zIndex: '99',
    },
    '&& span': {
      color: 'black',
    },
  },
  selected: {},
}))(MenuItem);

const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `url(${require('./sideBg.png')})`,
    backgroundSize: '164%',
    borderRight: 'none',
    filter: 'drop-shadow(1px 1px 0.4rem rgba(0,0,0,0.07))',
    top: 'unset',
    overflow: 'visible',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    maxWidth: '40%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '10%',
    },
  },
  logoText: {
    maxWidth: '38%',
    marginLeft: '0.5em',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '35%',
    },
  },
  desktopOnly: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  topLogo: {
    paddingTop: '2em',
    paddingBottom: '2em',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  welcome: {
    paddingTop: '0.6em',
    fontFamily: 'Lato',
    fontSize: '1.1em',
    paddingBottom: '0.5em',
  },
  user: {
    marginTop: '-0.3em',
    fontFamily: 'Lato',
    fontSize: '1.1em',
    fontWeight: 'bold',
    paddingBottom: '2em',
  },
  sidebarItem: {
    //  marginLeft: '2.7em'
  },
  sidebarItemLast: {
    position: 'fixed',
    bottom: '0',
    width: drawerWidth,
    backgroundColor: '#FFFFFF',
  },
  sidebarIcon: {
    marginRight: '-0.5em',
  },
  sidebarIconLast: {
    marginRight: '-0.5em',
  },
  appBarStyle: {
    top: 'unset',
    backgroundColor: 'white',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0), 0px 4px 5px 0px rgba(0,0,0,0), 0px 1px 10px 0px rgba(0,0,0,0)',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  sidebarTextLast: {
    marginLeft: '2.7em',
  },
  crownIcon: {
    height: '190%',
    width: '190%',
    marginRight: '-8.7em',
    opacity: '0.7',
  },
  crownIconSafari: {
    height: '190%',
    width: '4.3%',
    marginLeft: '23.5%',
    opacity: '0.7',
  },
});

const Sidenav = ({
  client,
  history,
  path,
  classes,
  selectedTab,
  userName,
  userRole,
  onSelectTab,
  windowSize,
  company,
}) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const flush = () => {
    client.resetStore();
    localStorage.clear();
  };

  const handleDrawerToggle = () => {
    if (windowSize.innerWidth < 600) setMobileOpen(!mobileOpen);
  };

  const handleSelect = tabId => selectedTab === tabId;

  const drawer = (
    <div>
      <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.topLogo}>
        <img src={djangoLogo} className={classes.logo} alt="Django Logo" />
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.desktopOnly}
      >
        <Typography component="h1" variant="h5" className={classes.welcome}>
          <FormattedMessage {...messages.welcome} />
        </Typography>
        <Typography component="h1" variant="h5" className={classes.user}>
          {userName}
        </Typography>
      </Grid>

      <MenuList style={{ marginBottom: '20%' }}>
        <NavLink to={`${path}/users`} style={{ textDecoration: 'none' }} onClick={() => onSelectTab('users')}>
          <StyledListItem button selected={handleSelect('users')}>
            <ListItemIcon>
              <PersonIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.users} />}
              className={classes.sidebarItem}
            />
          </StyledListItem>
        </NavLink>
        <NavLink to={`${path}/loans`} style={{ textDecoration: 'none' }} onClick={() => onSelectTab('loans')}>
          <StyledListItem button selected={handleSelect('loans')}>
            <ListItemIcon>
              <AssignmentTurnedInIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.loans} />}
              className={classes.sidebarItem}
            />
          </StyledListItem>
        </NavLink>
        <NavLink
          to={`${path}/payments`}
          style={{ textDecoration: 'none' }}
          onClick={() => onSelectTab('payments')}
        >
          <StyledListItem button selected={handleSelect('payments')}>
            <ListItemIcon>
              <PaymentIcon className={classes.sidebarIcon} />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage {...messages.payments} />}
              className={classes.sidebarItem}
            />
          </StyledListItem>
        </NavLink>
      </MenuList>
      <Mutation
        mutation={LOGOUT}
        variables={{ all: true }}
        onCompleted={() => history.push('/signin')}
        onError={() => flush()}
      >
        {logout => (
          <Typography onClick={() => logout()}>
            <ListItem button className={classes.sidebarItemLast}>
              <ListItemText
                primary={<FormattedMessage {...messages.logout} />}
                className={classes.sidebarTextLast}
              />
              <ListItemIcon>
                <ExitIcon className={classes.sidebarIconLast} />
              </ListItemIcon>
            </ListItem>
          </Typography>
        )}
      </Mutation>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBarStyle}>
        <Toolbar className={classes.appBarStyle}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <img src={djangoLogo} className={classes.logo} alt="Joe Logo" />
          </Grid>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

Sidenav.propTypes = {
  path: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onSelectTab: PropTypes.func.isRequired,
  selectedTab: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  windowSize: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Sidenav);
