import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import notchedOutline from '@material-ui/core/OutlinedInput';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage, injectIntl } from 'react-intl';
import RefreshIcon from '@material-ui/icons/Refresh';
import { toast } from 'react-toastify';

import {
  CommentCard,
  ConfirmRemoveAmlCftAlert,
  DeviceCard,
  DuplicatedBankAccountsCard,
  MissingInformationsCard,
  ProfileCard,
  UbbleCard,
} from './components';

import RelatedNotificationsContainer from './RelatedNotificationsContainer';
import RelatedLoansContainer from './RelatedLoansContainer';
import RelatedEventsContainer from './RelatedEventsContainer';
import RelatedAccountsContainer from './RelatedAccountsContainer';
import RelatedPromesseContainer from './RelatedPromessesContainer';
import RelatedFICPHistoriesContainer from './FICPContainer';
import PromesseModal from './PromesseModal';

import ME from '../../../../../src/components/graphQL/queries/Me.gql';
import GET_USER from './getUser.gql';
import GET_USER_BY_SIGNATURE from './getUserBySignature.gql';
import MOCK_BANK_ACCOUNT from './mockBankAccount.gql';
import UPDATE_USER_ACTIVITY from './updateUserActivity.gql';
import UPDATE_EMAIL_VALIDATION from './updateEmailValidation.gql';
import RENEW_EMAIL from './renewEmail.gql';
import UPDATE_USER_KYC_VALIDATION from './updateUserKYCValidation.gql';
import UPDATE_USER_IDENTITY_DUPLICATION from './updateUserIdentityDuplication.gql';
import RESET_IDENTITY_VERIFICATION_ATTEMPS from './resetIdentityVerificationAttemps.gql';
import REMOVE_DUPLICATED_IBAN from './removeDuplicatedIban.gql';
import GET_ALL_CREDITS_BY_BORROWER_ID from './GetAllCreditsByBorrowerId.gql';
import ADMIN_EVENTS from './adminEvents.gql';
import GET_DUPLICATED_BANK_ACCOUNTS from './getDuplicatedBankAccountsFromUser.gql';
import GET_NOTIFICATIONS from './getNotificationsFromUsers.gql';
import HAS_ELIGIBLE_TRANSACTIONS from './hasEligibleTransactions.gql';
import RESET_FRAUD_SUSPICION from './resetFraudSuspicion.gql';
import DUPLICATED_ACCOUNTS from './getDuplicatedAccounts.gql';
import SEND_DEEPLINK_SMS_FOR_OVERDUE_PAYMENT from './sendDeepLinkSMSForOverduePayment.gql';
import GET_ALL_DEBT from './getAllDebt.gql';
import GET_KYC_BY_ID from './getKycById.gql';
import PROMISE_DEBT_PAYMENT from './promiseDebtPayment.gql';
import GET_DEBT_PAYEMNT_PROMISE_BY_USER_ID from './getDebtPaymentPromiseByUserId.gql';
import GET_LAST_SIGN_IN_ATTEMPT from './getLastSignInAttempt.gql';
import GET_LAST_DEVICE from './getLastDevice.gql';
import ADD_COMMENT from './addComment.gql';
import GET_CUSTOMER_REPORT from './getCustomerReport.gql';
import GET_USER_IDENTITY_DOCUMENTS from './getUserIdentityDocuments.gql';
import moment from 'moment';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
  },
}));

const EnhancedTableToolbar = () => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        <FormattedMessage id="components.utils.users" />
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  blackWarning: {
    position: 'relative',
    marginLeft: '5px',
    top: '2px',
    color: 'black',
    cursor: 'pointer',
    fontSize: 20,
  },
  redWarning: {
    position: 'relative',
    marginLeft: '5px',
    top: '2px',
    color: 'red',
    cursor: 'pointer',
    fontSize: 20,
  },
}));

const UsersContainer = ({ intl }) => {
  const [notificationDevice, setNotificationDevice] = useState(null);
  const [scaVerifier, setScaVerifier] = useState(null);
  const [user, setUser] = useState(null);
  const [hasEligibleTransactions, setHasEligibleTransactions] = useState(false);
  const [notifications, setNotifications] = useState(null);
  const [device, setDevice] = useState(null);
  const [riskScore, setRiskScore] = useState(null);
  const [duplicatedUser, setDuplicatedUser] = useState(null);
  const [duplicatedBankAccounts, setDuplicatedBankAccounts] = useState(null);
  const [relatedLoans, setRelatedLoans] = useState(null);
  const [relatedEvents, setRelatedEvents] = useState(null);
  const [relatedAccounts, setRelatedAccounts] = useState(null);
  const [fraudScoring, setFraudScoring] = useState(null);
  const [openConfirmAmlCftAlert, setOpenConfirmAmlCftAlert] = useState(false);
  const [isPromiseModalOpen, setIsPromiseModalOpen] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };
  const DEFAULT_MAX_KYC_ATTEMPS = 2;

  const { loading, refetch } = useQuery(GET_USER, {
    variables: {
      id,
    },
    onCompleted(response) {
      setUser({
        ...response?.getUser?.user,
        bankAccountsNbr: response?.biConnectionsSummary?.bankAccountsNbr,
      });
      setDevice(response?.getUser?.device);
      setRiskScore(response?.getUser?.riskScore);
      setFraudScoring(response?.getUser?.fraudScoring);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: duplicatedBankAccountsLoading, refetch: refetchDuplicatedBankAccounts } = useQuery(
    GET_DUPLICATED_BANK_ACCOUNTS,
    {
      variables: {
        id,
      },
      onCompleted(response) {
        setDuplicatedBankAccounts(response?.getDuplicatedBankAccountsFromUser);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { loading: hasEligibleTransactionsLoading, refetch: refetchHasEligibleTransactions } = useQuery(
    HAS_ELIGIBLE_TRANSACTIONS,
    {
      variables: {
        id,
      },
      onCompleted(response) {
        setHasEligibleTransactions(response?.hasEligibleTransactions);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const { refetch: refetchGetNotificationsFromUsers } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      id,
    },
    onCompleted(response) {
      setNotifications(response?.getNotificationsFromUsers);
    },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_USER_BY_SIGNATURE, {
    variables: {
      id,
    },
    onCompleted(response) {
      setDuplicatedUser(response.getUserBySignature);
    },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(GET_ALL_CREDITS_BY_BORROWER_ID, {
    variables: {
      borrowerId: id,
      status: {
        included: [],
        excluded: [],
      },
      pagination: {
        page: null,
        pageLimit: null,
      },
    },
    onCompleted(response) {
      setRelatedLoans(response.getAllCreditsByBorrowerId.credits);
    },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(ADMIN_EVENTS, {
    variables: {
      id,
    },
    onCompleted(response) {
      setRelatedEvents(response.adminEvents.events);
    },
    fetchPolicy: 'cache-and-network',
  });

  useQuery(DUPLICATED_ACCOUNTS, {
    variables: {
      id,
    },
    onCompleted(response) {
      setRelatedAccounts(response?.getDuplicatedAccounts?.users);
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: getDebtPaymentPromiseByUserIdData } = useQuery(GET_DEBT_PAYEMNT_PROMISE_BY_USER_ID, {
    variables: { userId: user?.id },
    skip: !user?.id,
  });
  const promisePayments = getDebtPaymentPromiseByUserIdData?.getDebtPaymentPromiseByUserId ?? [];

  const [updateUserActivity] = useMutation(UPDATE_USER_ACTIVITY, {
    onCompleted() {
      refetch();
    },
  });

  const [updateEmailValidation] = useMutation(UPDATE_EMAIL_VALIDATION, {
    onCompleted(res) {
      refetch();
      if (res?.updateEmailValidation)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [renewEmail, { loading: renewEmailLoading }] = useMutation(RENEW_EMAIL, {
    onCompleted(res) {
      if (res?.renewEmail)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [addComment, { loading: addCommentLoading }] = useMutation(ADD_COMMENT, {
    onCompleted(res) {
      if (res?.addComment)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [mockBankAccountMutation, { loading: mockBankAccountLoading }] = useMutation(MOCK_BANK_ACCOUNT, {
    onCompleted(res) {
      if (res?.mockBankAccount) {
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
        refetch();
        refetchHasEligibleTransactions();
      } else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [resetIdentityVerificationAttemps] = useMutation(RESET_IDENTITY_VERIFICATION_ATTEMPS, {
    onCompleted(res) {
      refetch();
      if (res?.resetIdentityVerificationAttemps)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [resetFraudSuspicion] = useMutation(RESET_FRAUD_SUSPICION, {
    onCompleted(res) {
      refetch();
      if (res?.resetFraudSuspicion)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [updateUserKYCValidation] = useMutation(UPDATE_USER_KYC_VALIDATION, {
    onCompleted(res) {
      refetch();
      getKycByIdRefetch();
      if (res?.updateUserKYCValidation)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [updateUserIdentityDuplication] = useMutation(UPDATE_USER_IDENTITY_DUPLICATION, {
    onCompleted(res) {
      refetch();
      if (res?.updateUserIdentityDuplication)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const [removeDuplicatedIban] = useMutation(REMOVE_DUPLICATED_IBAN, {
    onCompleted(res) {
      refetch();
      refetchDuplicatedBankAccounts();
      if (res?.removeDuplicatedIban)
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), toastOptions);
      else toast.error(intl.formatMessage({ id: 'components.admin.failed' }), toastOptions);
    },
    onError(err) {
      const [error] = err.graphQLErrors;
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error?.code, toastOptions);
    },
  });

  const { data: userIdentityDocuments } = useQuery(GET_USER_IDENTITY_DOCUMENTS, {
    variables: { userId: user?.id },
    skip: !user?.id,
  });
  const profilePicture = userIdentityDocuments?.getUserIdentityDocuments?.frontFaceImage;
  const userDocumentsUrls = [
    userIdentityDocuments?.getUserIdentityDocuments?.frontIdentityDocument,
    userIdentityDocuments?.getUserIdentityDocuments?.backIdentityDocument,
  ].filter(doc => doc);

  const { data: allDebtData } = useQuery(GET_ALL_DEBT, { variables: { userId: user?.id }, skip: !user?.id });
  const unpaidDebt = allDebtData?.getAllDebt?.find(
    debt => (debt.status === 'UNPAID' || debt.status === 'UNPAID_WITH_PENALTY') && debt.userId === user?.id,
  );
  const [sendDeepLinkSMSForOverduePayment] = useMutation(SEND_DEEPLINK_SMS_FOR_OVERDUE_PAYMENT);

  const updateActivityMutation = active => {
    updateUserActivity({
      variables: {
        ids: [user?.id],
        active,
      },
    });
  };
  const [promiseDebtPayment] = useMutation(PROMISE_DEBT_PAYMENT, {
    refetchQueries: ['getDebtPaymentPromiseByUserId'],
  });

  const submitPromiseDebtPayment = async ({ date, description }) => {
    console.log({ date, description });
    await promiseDebtPayment({
      variables: {
        userId: user.id,
        message: description,
        promisedDate: date.toISOString(),
      },
    });
  };

  const {
    loading: getKycByIdLoading,
    data: kycData,
    refetch: getKycByIdRefetch,
  } = useQuery(GET_KYC_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const newKyc = kycData?.getKycById;

  const { loading: getLastDeviceLoading, refetch: getLastDeviceRefetch } = useQuery(GET_LAST_DEVICE, {
    variables: {
      userId: id,
    },
    onCompleted(response) {
      setNotificationDevice(response.getLastDevice);
    },
    fetchPolicy: 'cache-and-network',
  });

  const { loading: getLastSignInAttemptLoading, refetch: getLastSignInAttemptRefetch } = useQuery(
    GET_LAST_SIGN_IN_ATTEMPT,
    {
      variables: {
        userId: id,
      },
      onCompleted(response) {
        setScaVerifier(response.getLastSignInAttempt);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const updateUserKYCValidationMutation = identityVerified => {
    updateUserKYCValidation({
      variables: {
        id: user?.id,
        identityVerified,
      },
    });
  };

  const resetFraudSuspicionMutation = () => {
    resetFraudSuspicion({
      variables: {
        id: user?.id,
      },
    });
  };

  const updateUserIdentityDuplicationMutation = () => {
    updateUserIdentityDuplication({
      variables: {
        id: user?.id,
      },
    });
  };

  const sendRemoveDuplicatedIbanMutation = id => {
    removeDuplicatedIban({
      variables: {
        id,
      },
    });
  };

  const refetchAll = () => {
    refetch();
    refetchDuplicatedBankAccounts();
    refetchGetNotificationsFromUsers();
    getKycByIdRefetch();
    refetchHasEligibleTransactions();
    getKycByIdRefetch();
    getLastSignInAttemptRefetch();
    getLastDeviceRefetch();
    getCustomerReportRefetch();
  };
  //AJOUTER UN COMMENTAIRE///////////////////////////////////////////////////////////////
  const [operatorName, setOperatorName] = useState('');
  const [operatorId, setOperatorId] = useState('');

  useQuery(ME, {
    onCompleted(response) {
      setOperatorId(response.Me.id);
      setOperatorName(`${response.Me.firstname} ${response.Me.lastname}`);
    },
  });

  const [isCommentOpen, setCommentOpen] = useState(false);
  const [isCancelPopupOpen, setCancelPopupOpen] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [shouldCloseDialog, setShouldCloseDialog] = useState(true);

  const handleOpenComment = () => {
    setCommentOpen(true);
    setCommentText('');
  };

  const handleCloseComment = () => {
    if (shouldCloseDialog) {
      setCommentOpen(false);
    }
    setShouldCloseDialog(true);
  };

  const handleSaveComment = async () => {
    await addComment({
      variables: {
        userId: user.id,
        content: commentText,
        creator: operatorId,
      },
    });
    setComments([
      {
        createdAt: moment().format('DD-MM-YYYY HH:mm'),
        content: commentText,
        creator: operatorName,
      },
      ...comments,
    ]);
    setCommentOpen(false);
  };

  const handleCancel = () => {
    setShouldCloseDialog(true);
    setCancelPopupOpen(true);
  };

  const handleCloseCancelPopup = () => {
    setCancelPopupOpen(false);
  };

  const handleConfirmCancel = () => {
    setCancelPopupOpen(false);
    handleCloseComment();
  };

  const handleBackdropClick = e => {
    e.stopPropagation();
    setShouldCloseDialog(false);
  };
  //FIN AJOUTER UN COMMENTAIRE//////////////////////////////
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  ///////AFFICHAGE COMMENTAIRES/////////////////////////////
  const { loading: getCustomerReportLoading, refetch: getCustomerReportRefetch } = useQuery(
    GET_CUSTOMER_REPORT,
    {
      variables: {
        userId: id,
        page: page,
      },
      onCompleted(response) {
        const transformedData = response.getCustomerReport.customerReport.map(originalComment => {
          const { content, creator, createdAt } = originalComment;
          return {
            createdAt: createdAt,
            content,
            creator: `${creator.firstname} ${creator.lastname}`,
          };
        });
        setComments(transformedData);
        setTotalPages(response.getCustomerReport.totalPages);
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [comments, setComments] = useState([]);
  const [commentLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCustomerReportRefetch({ userId: id, page: 0 }).then(() => {
      setLoading(false);
    });
  }, [getCustomerReportRefetch, id]);

  const onPageChange = async page => {
    setLoading(true);
    setPage(page - 1);
    await getCustomerReportRefetch({ userId: id, page: page }).then(() => {
      setLoading(false);
    });
  };

  ///////////FIN AFFICHAGE COMMENTAIRES////////////////////////////////////////////////////////

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" style={{ marginTop: 20, marginBottom: 20 }}>
        <Button
          variant="contained"
          color="base"
          style={{ alignSelf: 'flex-end' }}
          onClick={() => history.goBack()}
        >
          Retour
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 20, alignSelf: 'flex-end' }}
          onClick={() => refetchAll()}
        >
          <RefreshIcon /> Actualiser
        </Button>
        {mockBankAccountLoading ? (
          <CircularProgress style={{ marginLeft: 60 }} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => mockBankAccountMutation({ variables: { id: user.id } })}
          >
            Create Bank
          </Button>
        )}
        {user && !user?.emailVerified && (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 20 }}
              onClick={() => updateEmailValidation({ variables: { id: user.id } })}
            >
              Valider Email
            </Button>
            {renewEmailLoading ? (
              <CircularProgress style={{ marginLeft: 60 }} />
            ) : (
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 20 }}
                onClick={() => renewEmail({ variables: { userId: user.id, email: user.email } })}
              >
                Renvoyer Email
              </Button>
            )}
          </>
        )}
        {!user?.identityVerified && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => updateUserKYCValidationMutation(true)}
          >
            Valider KYC
          </Button>
        )}
        {user?.amlCftAlert && !user?.adminAuthorization && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => setOpenConfirmAmlCftAlert(true)}
          >
            Retirer Alerte AML & CFT
          </Button>
        )}
        {user && !user?.identityVerified && user?.ubbleVerificationAttempts >= DEFAULT_MAX_KYC_ATTEMPS && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => resetIdentityVerificationAttemps({ variables: { id: user.id } })}
          >
            Réinitialiser KYC
          </Button>
        )}
        {user?.identityDuplication && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => updateUserIdentityDuplicationMutation()}
          >
            Retirer duplication identité
          </Button>
        )}
        {user?.fraudSuspicion && !user?.adminAuthorization && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={() => resetFraudSuspicionMutation()}
          >
            Retirer suspicion fraude
          </Button>
        )}
        {unpaidDebt && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 20 }}
            onClick={async () => {
              await sendDeepLinkSMSForOverduePayment({
                variables: {
                  userId: user.id,
                  loanId: unpaidDebt.loanId,
                  unpaidAmount: unpaidDebt.unpaidAmount,
                },
              });
            }}
          >
            ENVOYER LIEN REGULARISATION
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 20 }}
          onClick={() => {
            setIsPromiseModalOpen(true);
          }}
        >
          AJOUTER UNE PROMESSE
        </Button>
        <>
          <Button variant="contained" color="primary" style={{ marginLeft: 20 }} onClick={handleOpenComment}>
            AJOUTER UN COMMENTAIRE
          </Button>
          <Dialog
            open={isCommentOpen}
            onClose={handleCloseComment}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
          >
            <DialogTitle>Commentaire</DialogTitle>
            <DialogContent onClick={handleBackdropClick}>
              <TextField
                multiline
                minRows={8}
                variant="outlined"
                fullWidth
                value={commentText}
                onChange={e => setCommentText(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} variant="containedSecondary" color="secondary">
                ANNULER
              </Button>
              <Button onClick={handleSaveComment} variant="contained" color="primary">
                ENREGISTRER
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={isCancelPopupOpen}
            onClose={handleCloseCancelPopup}
            maxWidth="sm"
            fullWidth
            disableBackdropClick
          >
            <DialogTitle>
              <strong>Souhaitez-vous annuler?</strong>
            </DialogTitle>
            <DialogContent onClick={handleBackdropClick}>
              <p>Si vous annulez, le contenu ne sera pas enregistré.</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCancelPopup} variant="contained" color="primary">
                NON
              </Button>
              <Button onClick={handleConfirmCancel} variant="containedSecondary" color="secondary">
                OUI
              </Button>
            </DialogActions>
          </Dialog>
        </>
      </Grid>
      <ProfileCard
        notificationDevice={notificationDevice}
        scaVerifier={scaVerifier}
        newKyc={newKyc}
        user={user}
        hasEligibleTransactions={hasEligibleTransactions}
        profilePicture={profilePicture}
        loading={loading}
        hasEligibleTransactionsLoading={hasEligibleTransactionsLoading}
        getKycByIdLoading={getKycByIdLoading}
        getLastDeviceLoading={{ getLastDeviceLoading }}
        getLastSignInAttemptLoading={getLastSignInAttemptLoading}
        riskScore={riskScore}
        fraudScoring={fraudScoring}
        device={device}
      />
      <CommentCard
        comments={comments}
        loading={commentLoading}
        totalPages={totalPages}
        onPageChange={async page => await onPageChange(page)}
      />
      <DeviceCard device={device} loading={loading} />
      <MissingInformationsCard user={user} duplicatedUser={duplicatedUser} loading={loading} />
      <UbbleCard user={user} loading={getKycByIdLoading || loading} newKyc={newKyc} />
      <DuplicatedBankAccountsCard
        duplicatedBankAccounts={duplicatedBankAccounts}
        onClick={sendRemoveDuplicatedIbanMutation}
        loading={duplicatedBankAccountsLoading}
      />
      <ConfirmRemoveAmlCftAlert
        user={user}
        openConfirmAmlCftAlert={openConfirmAmlCftAlert}
        setOpenConfirmAmlCftAlert={setOpenConfirmAmlCftAlert}
      />
      <div style={{ marginTop: 10, width: '100%' }}>
        {userDocumentsUrls.length &&
          userDocumentsUrls.map(fileUrl => <img src={fileUrl} alt="kyc" style={{ width: '50%' }} />)}
        {notifications?.length ? (
          <div style={{ marginTop: 30 }}>
            <RelatedNotificationsContainer intl={intl} notifications={notifications} />
          </div>
        ) : null}
        {id ? (
          <div style={{ marginTop: 30 }}>
            <RelatedFICPHistoriesContainer intl={intl} userId={id} />
          </div>
        ) : null}
        {id ? (
          <div style={{ marginTop: 30 }}>
            <RelatedLoansContainer intl={intl} borrowerId={id} />
          </div>
        ) : null}
        {relatedEvents?.length ? (
          <div style={{ marginTop: 30 }}>
            <RelatedEventsContainer intl={intl} relatedEvents={relatedEvents} />
          </div>
        ) : null}
        {relatedAccounts?.length && user?.fraudSuspicion ? (
          <div style={{ marginTop: 30 }}>
            <RelatedAccountsContainer intl={intl} relatedAccounts={relatedAccounts} />
          </div>
        ) : null}
      </div>
      {promisePayments.length ? (
        <RelatedPromesseContainer promesses={promisePayments}></RelatedPromesseContainer>
      ) : null}
      <PromesseModal
        key={isPromiseModalOpen}
        onSubmit={submitPromiseDebtPayment}
        open={isPromiseModalOpen}
        setOpen={setIsPromiseModalOpen}
      />
    </div>
  );
};

export default injectIntl(UsersContainer);
