import PropTypes from 'prop-types';

// Router imports
import { Route, Redirect } from 'react-router-dom';

// i18n imports
import { injectIntl } from 'react-intl';

// Utilities imports
import ReactTimeout from 'react-timeout';
import { useStore } from '../../hooks/useStore';

const ProtectedRoute = ({ path, component: Component, render, setTimeout, intl, client, ...rest }) => {
  const { isLoggedIn: isUserLoggedIn } = useStore();
  return (
    <Route
      {...rest}
      render={props => {
        if (isUserLoggedIn === false)
          return (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: props.location || '/signin' },
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  location: null,
  component: null,
};

ProtectedRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  render: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  setTimeout: PropTypes.func.isRequired,
};

export default injectIntl(ReactTimeout(ProtectedRoute));
