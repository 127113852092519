import React from 'react';
import { Dialog, DialogContent, DialogActions, Grid, makeStyles, DialogTitle } from '@material-ui/core';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { DynamicBoldText } from './DynamicBoldText';

const useStyles = makeStyles(theme => ({
  continueButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#fff',
    borderColor: '#FF6B4F',
    backgroundColor: '#FF6B4F',
  },
  title: {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 800,
    color: '#081433',
    lineHeight: '38.4px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '23px',
    fontWeight: 500,
    color: '#081433',
    lineHeight: '27.6px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
  checkIcon: {
    color: '#FF6B4F',
    width: '50px',
    height: '50px',
    marginTop: '16px',
  },
  actionButton: { paddingTop: '16px', paddingBottom: '16px' },
}));

export const RetryUnpaidInstallmentResultDialog = ({
  title,
  description,
  boldText,
  open,
  onClose,
  error,
}) => {
  const classes = useStyles();
  const dialogStyle = {
    style: {
      borderRadius: '16px',
      width: '50%',
    },
  };
  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <Dialog maxWidth={'md'} PaperProps={dialogStyle} open={open} onClose={handleCloseDialog}>
      <DialogTitle>
        {error ? <Cancel className={classes.checkIcon} /> : <CheckCircle className={classes.checkIcon} />}
      </DialogTitle>
      <DialogTitle>
        <div className={classes.title}>{title}</div>
      </DialogTitle>
      <DialogContent>
        {description && <DynamicBoldText text={description} boldText={boldText} />}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.actionButton}
        >
          <Grid item>
            <button className={classes.continueButton} onClick={handleCloseDialog}>
              Terminer
            </button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
