import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Logo,
  Button,
  Icon,
  Text,
  Loading,
  ButtonText,
  PhoneWrapper,
  CenterWrapper,
  TextResponse,
  Subtitle,
} from './components';
import { useParams } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import djangoLogo from '../../../images/djangoLogo.png';
import IconLogo from './cheerleader.png';
import LINK_REFERRAL from './linkReferral.gql';

const JOE_APP_STORE_URL = 'https://apps.apple.com/fr/app/id1522534449';
const JOE_PLAY_STORE_URL = 'https://play.google.com/store';

const Referrals = () => {
  const [phone, setPhone] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [forbidden, setForbidden] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const { referralCode } = useParams();

  const [linkReferral] = useMutation(LINK_REFERRAL, {
    onCompleted() {
      if (redirectUrl) redirectToStore();
      else setSuccess(true);
      setError(false);
      setForbidden(false);
      setLoading(false);
    },
    onError(error) {
      const [err] = error.graphQLErrors;
      if (err && err.code === 'EXISTING_REFERRAL') {
        setForbidden(true);
      } else {
        setError(true);
        setTimeout(() => setError(false), 4000);
      }
      setLoading(false);
    },
  });

  const sendLinkReferralMutation = () => {
    setLoading(true);
    linkReferral({
      variables: {
        referralCode: referralCode || null,
        phone: `+${phone}`,
        sendSms: !redirectUrl,
      },
    });
  };

  const redirectToStore = () => {
    window.location.href = redirectUrl;
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (iosPlatforms.indexOf(platform) !== -1) {
      setRedirectUrl(JOE_APP_STORE_URL);
    } else if (userAgent.match(/Android/)) {
      setRedirectUrl(JOE_PLAY_STORE_URL);
    }
  }, [setRedirectUrl]);

  return (
    <CenterWrapper>
      <Logo src={djangoLogo} />
      <Icon style={{ marginTop: '4em' }} src={IconLogo} />
      <Text>On vous invite à rejoindre joe</Text>
      <Subtitle>
        Inscrivez-vous à notre liste d'attente pour bénéficier de votre parrainage et invitez vos amis
      </Subtitle>
      <PhoneWrapper style={{ marginTop: '3em' }}>
        <PhoneInput
          containerStyle={{
            height: 55,
            borderRadius: 10,
          }}
          inputStyle={{
            height: 55,
            width: 280,
            borderRadius: 10,
            border: phone && phone.length < 11 ? '1px solid red' : null,
          }}
          country={'fr'}
          value={phone}
          onChange={phone => setPhone(phone)}
        />
      </PhoneWrapper>
      {(!success && !loading) || (forbidden && redirectUrl) ? (
        <Button
          style={{ marginTop: '1em', marginBottom: '1em' }}
          disabled={!phone || phone.length < 11}
          onClick={redirectUrl && forbidden ? redirectToStore : sendLinkReferralMutation}
        >
          <ButtonText>
            {redirectUrl && forbidden ? 'Téléchargez Joe maintenant' : "S'inscrire maintenant"}
          </ButtonText>
        </Button>
      ) : (
        <>
          {loading ? (
            <Loading width={50} height={50} style={{ marginTop: '1em' }} />
          ) : (
            <TextResponse color={'#19CFF1'}>
              Votre numéro de téléphone a bien été enregistré. Vous allez recevoir un SMS.
            </TextResponse>
          )}
        </>
      )}
      {(error || forbidden) && (
        <TextResponse color={'red'}>
          {error
            ? 'Un problème est survenu, veuillez réessayer.'
            : "Vous avez déjà été parrainé. Vous ne pouvez l'être plus d'une fois"}
        </TextResponse>
      )}
    </CenterWrapper>
  );
};

export default Referrals;
