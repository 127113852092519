import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import djangoTheme from './styles/djangoTheme';

import apolloClient from './graphql/ApolloClient';
import LanguageProvider from './i18n/LangageProvider';
import MainRouter from './routes/MainRouter';

import './app.css';

class App extends React.Component {
  state = {};

  djangoTheme = createTheme(djangoTheme);

  constructor(props) {
    super(props);

    this.state.locale = props.defaultLocale;
  }

  changeLocale = locale => this.setState({ locale });

  render = () => {
    const { messages } = this.props;
    const { locale } = this.state;
    return (
      <ApolloProvider client={apolloClient}>
        <MuiThemeProvider theme={this.djangoTheme}>
          <CssBaseline />

          <LanguageProvider locale={locale} defaultLocale="fr" messages={messages}>
            <BrowserRouter>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <MainRouter client={apolloClient} />
              </MuiPickersUtilsProvider>
            </BrowserRouter>
          </LanguageProvider>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  };
}

App.propTypes = {
  messages: PropTypes.object.isRequired,
  defaultLocale: PropTypes.string.isRequired,
};

export default App;
