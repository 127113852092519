import React from 'react';
import { PropTypes } from 'prop-types';

// Material UI component imports
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// i18n imports
import { FormattedMessage } from 'react-intl';

// Styles imports
import CommonStyles from '../../styles/common';

const styles = theme => ({
  ...CommonStyles(theme),
  link: {
    color: theme.palette.darkBlue,
    textDecoration: 'none',
  },
});

const BlockedSignin = ({ open, classes, blocked, wrongBrowser }) => {
  const handleConditionalDialogTitle = () => {
    if (blocked) return <FormattedMessage id="components.utils.blocked" />;
    // if (wrongBrowser) return <FormattedMessage id="components.utils.wrongBrowserTitle" />
    else return null;
  };

  const handleConditionalDialogContent = () => {
    /* if (blocked) return (
      <>
          <FormattedMessage id="components.utils.blockedHelperText" />
          {' '}
          <FormattedMessage id="components.utils.supportEmail">
            {txt => (
              <a className={classes.link} href="mailto:support@getjoe.co">
                {txt}
              </a>
            )}
          </FormattedMessage>
      </>
    )
    if (wrongBrowser) return (
      <>
      <FormattedMessage id="components.utils.wrongBrowserContent" />
      <ul style={{ listStyleType: 'none', marginRight: '1.5em' }}>
      <li><FormattedMessage id="components.utils.chrome">
        {txt => (
          <a className={classes.link} href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
            {txt}
          </a>
        )}
      </FormattedMessage>
      </li>
      <li>
      <FormattedMessage id="components.utils.firefox">
        {txt => (
          <a className={classes.link} href="https://www.mozilla.org/firefox/" target="_blank" rel="noopener noreferrer">
            {txt}
          </a>
        )}
      </FormattedMessage>
      </li>
      </ul>
  </>
    ) */
    return null;
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{handleConditionalDialogTitle()}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {handleConditionalDialogContent()}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

BlockedSignin.propTypes = {
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  blocked: PropTypes.bool.isRequired,
  wrongBrowser: PropTypes.bool.isRequired,
};

export default withStyles(styles)(BlockedSignin);
