/* eslint-disable react/destructuring-assignment */
// Material UI imports
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

import { injectIntl } from 'react-intl';

import UsersContainer from './components/UsersContainer';

// Dashboard container import
import DashboardContainer from '../DashboardContainer';

const Users = props => {
  const { windowSize, intl, history } = props;

  const fullPanel = [
    {
      id: 'Users',
      component: <UsersContainer intl={intl} windowSize={windowSize} />,
    },
  ];

  return (
    <Grid item xs={12}>
      <DashboardContainer
        history={history}
        standalone
        displayWarning={false}
        fullPanel={fullPanel}
        panelStyle="fullPanel"
        {...props}
        windowSize={windowSize}
      />
    </Grid>
  );
};

Users.propTypes = {
  history: PropTypes.object.isRequired,
  windowSize: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Users);
