import { red } from '@material-ui/core/colors';

const djangoTheme = {
  breakpoints: {
    values: {
      xs: 0,
      s: 400,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    textFieldsOutline: '#87caef',
    TextFieldsOutlineHovered: '#000000',
    warningOrange: '#FFAF39',
    darkBlue: '#87caef',
    warning: { 500: red[500] },
    warningShadow: '0px 1px 5px rgba(206,0,0,0.28)',
    selectionBlue: '#84c9f1',
    lightBlue: '#8aeef2',
    disabled: '#d6d6d6',
    hoverBut2: '#dadada',
    inactiveLight: '#ececec',
    inactive: '#bababa',
    inactiveSelect: '#ababab',
    grayed: '#6e6e6e',
    uploadText: '#bdbdbd',
    mainGradient: 'linear-gradient(45deg, #87caef 0%, #8AEEF2 100%)',
    hovered: '#d6d6d62b',
    // For buttons with a more pornounced gradient
    enhancedGradient:
      'linear-gradient(90deg, rgba(135,202,239, 1) 5%, rgba(138,238,242,1) 54%, rgba(180,244,247,1) 84%)',
    secondaryGradient:
      'linear-gradient(90deg, rgba(255,133,130,1) 100%, rgba(255,239,236,1) 100%, rgba(255,239,236,1) 100%)',
    // to modify gradient if needed: https://cssgradient.io/
    secondaryBackground: '#FFFFFF',
    standardTextColorWithBackground: '#FFFFFF',
    standardTextColorNoBackground: '#000000',
    textFieldOverride: '#ababab',
  },

  typography: {
    fontFamily: ['Lato', 'Roboto', 'Helvetica', 'Arial', 'Poppins', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Lato',
      fontWeight: 300,
      fontSize: '2.3rem',
      textTransform: 'uppercase',
      lineHeight: 1,
      letterSpacing: '-0.01562em',
    },
    h2: {
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '1.01rem',
      textTransform: 'uppercase',
    },
    subtitle1: {
      color: '#bababa',
      fontFamily: 'Lato',
      fontSize: '1em',
      opacity: '0.9',
      lineHeight: 1.25,
    },
    button: {
      color: 'white',
      fontFamily: 'Lato',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
      '@media (max-width: 599.95px) and (min-width: 0px)': {
        textTransform: 'capitalize',
      },
    },
  },
  spacing: 8,
  custom: {
    borderRadius: 8,
    standardOpacity: 0.75,
    standardShadow: '0px 79px 112px -86px rgba(0,0,0,0.81)',
    // to modify box shadow if needed: https://www.cssmatic.com/box-shadow
  },
  overrides: {
    // Name of the ⚛️ component / style sheet
    MuiLinearProgress: {
      // Name of the rule
      colorPrimary: {
        backgroundColor: '#bababa',
      },
      colorSecondary: {
        backgroundColor: '#bababa',
      },
      barColorPrimary: {
        backgroundColor: '#6ddeff',
      },
      barColorSecondary: {
        backgroundColor: '#bababa',
      },
    },
    MuiRadio: {
      /* Styles applied to the root element if `color="primary"`. */
      colorPrimary: {
        '&$checked': {
          color: '#000000',
        },
        '&$disabled': {
          color: '#d6d6d6',
        },
      },
    },
  },
};

export default djangoTheme;
