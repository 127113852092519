/* eslint-disable implicit-arrow-linebreak */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';

// Material UI imports
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

import { toast } from 'react-toastify';
import { Query, Mutation } from '@apollo/client/react/components';
import { injectIntl } from 'react-intl';

// GraphQL queries and mutations imports
import ME from '../../components/graphQL/queries/Me.gql';
import logoutGQL from '../../components/graphQL/mutations/auth/logout.gql';

import CommonStyles from '../../styles/common';

import Loading from '../../components/common/Loading';
import MyAccount from './MyAccount';
import Sidenav from './Sidenav';
import Users from './Users';
import User from './User';
import Loans from './Loans';
import Payments from './Payments';
import { CurrencyProvider } from '../../components/common/CurrencyContext';
import UserContext from './UserContext';
import { FilterProvider } from './FilterContext';
import UserLoggedInContext from '../UserLoggedInContext';

// Component specific styling (Mui Theme overrides)
const styles = theme => ({
  ...CommonStyles(theme),
  main: {
    width: theme.spacing(2),
  },
  warning: {
    width: '100%',
    height: '6vh',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.warningOrange,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

/**
 * Layout container - Query must be separated from child component for performance issues
 * @param props
 * @return {*}
 * @constructor
 */
const LayoutContainer = props => {
  const { history, intl, client } = props;
  const [_, setIsLoggedIn] = useContext(UserLoggedInContext);

  return (
    <Query query={ME} fetchPolicy="network-only">
      {({ loading, error, data }) => {
        if (loading) return <Loading />;
        if (error) {
          return (
            <Mutation mutation={logoutGQL} variables={{ all: true }}>
              {() => {
                if (error.networkError) {
                  toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  });
                } else {
                  toast.error(
                    intl.formatMessage({
                      id: 'components.session.sessionExpired',
                    }) + error.message,
                    {
                      position: 'bottom-center',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                    },
                  );
                }
                setIsLoggedIn(false);
                client.resetStore();
                localStorage.clear();
                history.push('/signin');
                return null;
              }}
            </Mutation>
          );
        }
        // if (data.Me.emailVerified) {
        return (
          <UserContext.Provider value={data.Me}>
            <DashboardLayout user={data.Me} client={client} {...props} />
          </UserContext.Provider>
        );
        // }

        /* toast.error('Please validate your account before logging in.', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false
          });

          return (
            <Mutation mutation={logoutGQL} variables={{ all: true }}>
              {() => {
                client.resetStore();
                localStorage.clear();
                flushCookie();
                history.push('/?msg=err_token_expired');
                return null;
              }}
            </Mutation>
          ); */
      }}
    </Query>
  );
};

LayoutContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

const DashboardLayout = ({ client, match, history, user /* , classes */ }) => {
  const [accountActivated, setAccountActivated] = useState(false);

  const getSize = () => ({
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  });

  const lastPath = document.URL.split('/').pop();
  const defaultTab = lastPath === 'dashboard' ? 'transaction' : lastPath;

  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [windowSize, setWindowSize] = useState(getSize());
  const [userName, setUserName] = useState('Unknown user');
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  useEffect(() => {
    if (user) {
      setUserName(`${user.firstname} ${user.lastname}`);
      setUserRole(`${user.userRole}`);
      if (!user.active) history.push('/signin/blocked');
      if (!user.kycDocumentsReview) {
        if (accountActivated) setAccountActivated(false);
      } else if (!accountActivated) setAccountActivated(true);
    }
  }, [accountActivated, history, user]);

  const { path } = match;

  if (!user) return '';

  return (
    <CurrencyProvider value={'EUR'}>
      {/* accountActivated
       ? (
       <div className={classes.warning}>
       <FormattedMessage id="components.warning.uploadDocs" />
       </div>
       (null)
       )
       : (null)
       */}
      <Grid container wrap="nowrap">
        <Sidenav
          client={client}
          history={history}
          // company={user.account.name}
          path={path}
          userName={userName}
          userRole={userRole}
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
          windowSize={windowSize}
        />
        <FilterProvider>
          <Switch>
            <Route
              exact
              path={`${path}/users`}
              render={props => <Users {...props} windowSize={windowSize} />}
            />
            <Route path={`${path}/users/:id`} render={props => <User {...props} windowSize={windowSize} />} />
            <Route path={`${path}/loans`} render={props => <Loans {...props} windowSize={windowSize} />} />
            <Route
              path={`${path}/payments`}
              render={props => <Payments {...props} windowSize={windowSize} />}
            />
            <Route
              path={`${path}/me`}
              render={props => <MyAccount {...props} client={client} windowSize={windowSize} />}
            />
            <Route exact path="/dashboard" render={props => <Users {...props} windowSize={windowSize} />} />
          </Switch>
        </FilterProvider>
      </Grid>
    </CurrencyProvider>
  );
};

DashboardLayout.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  client: PropTypes.object.isRequired,
};

DashboardLayout.defaultProps = {
  user: null,
};

export default injectIntl(withStyles(styles)(LayoutContainer));
