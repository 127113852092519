import { useCalendarCell, useCalendarGrid, useLocale, useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';
import { createCalendar, getWeeksInMonth, today, isSameDay } from '@internationalized/date';
import {
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

export const TimeRanges = {
  7: 7,
  15: 15,
  30: 30,
  60: 60,
};

/**
 *
 * @param {import("react-stately").RangeCalendarStateOptions} props
 * @returns
 */
export function RangeCalendar(props) {
  const { selectedDateRange, setSelectedDateRange } = props;
  const onSelectRange = timeRange => () => {
    setSelectedDateRange({
      start: today().subtract({ days: timeRange }),
      end: today(),
    });
  };

  let { locale } = useLocale();
  let state = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
    value: selectedDateRange,
    onChange: setSelectedDateRange,
  });

  const ref = useRef(null);
  let { calendarProps, prevButtonProps, nextButtonProps, title } = useRangeCalendar(props, state, ref);
  return (
    <Grid {...calendarProps} container direction="column" style={{ padding: '16px' }} ref={ref}>
      <Grid item style={{ padding: '8px 0px' }}>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item>
            <Button {...prevButtonProps} onClick={prevButtonProps.onPress}>
              <ChevronLeft style={{ width: 24, height: 24, color: '#FF6B4F' }} />
            </Button>
          </Grid>
          <Grid item>
            <Typography
              style={{
                fontFamily: 'Poppins',
                fontsize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '22px',
                color: '#FF6B4F',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Button {...nextButtonProps} onClick={nextButtonProps.onPress}>
              <ChevronRight style={{ width: 24, height: 24, color: '#FF6B4F' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item style={{ padding: '8px 0px' }}>
        <Grid container direction="row" alignItems="center" justifyContent="space-between">
          {Object.values(TimeRanges).map((range, index) => (
            <Grid item key={index}>
              <Button
                onClick={onSelectRange(range)}
                style={{
                  backgroundColor: '#F8F9FB',
                  borderWidth: '1px',
                  borderColor: '#E6E6E9',
                  padding: '8px',
                  borderRadius: '8px',
                  width: '181px',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '22px',
                    color: '#5D6067',
                  }}
                >
                  {range + ' derniers jours'}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <CalendarGrid state={state} />
    </Grid>
  );
}

function CalendarGrid({ state, ...props }) {
  let { locale } = useLocale();
  let { gridProps, headerProps, weekDays } = useCalendarGrid({ ...props, weekdayStyle: 'long' }, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  return (
    <TableContainer>
      <Table {...gridProps} style={{ tableLayout: 'fixed' }}>
        <TableHead {...headerProps}>
          <TableRow>
            {weekDays.map(day => (
              <TableCell
                key={day}
                style={{
                  fontFamily: 'Poppins',
                  fontsize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '16.94px',
                  color: '#000',
                  textAlign: 'center',
                  borderColor: 'transparent',
                }}
              >
                {day}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...new Array(weeksInMonth).keys()].map(weekIndex => (
            <TableRow key={weekIndex} style={{ paddingTop: '50px' }}>
              {state.getDatesInWeek(weekIndex).map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} last={weekIndex === weeksInMonth - 1} />
                ) : (
                  <TableCell
                    key={i}
                    style={{
                      fontFamily: 'Poppins',
                      fontsize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '22px',
                      color: '#FF6B4F',
                      borderColor: '#FF6B4F',
                    }}
                  />
                ),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CalendarCell({ state, date, last }) {
  let ref = React.useRef(null);
  let { cellProps, buttonProps, isSelected, isDisabled, formattedDate } = useCalendarCell(
    { date, last },
    state,
    ref,
  );
  let isSelectionStart = state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected;
  let isSelectionEnd = state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected;

  let isRoundedLeft = isSelected && isSelectionStart;
  let isRoundedRight = isSelected && isSelectionEnd;
  return (
    <TableCell
      {...cellProps}
      style={{
        borderColor: 'transparent',
        backgroundColor: isSelected ? '#FF6B4F' : 'transparent',
        borderTopLeftRadius: isRoundedLeft ? '32px' : '',
        borderBottomLeftRadius: isRoundedLeft ? '32px' : '',
        borderTopRightRadius: isRoundedRight ? '32px' : '',
        borderBottomRightRadius: isRoundedRight ? '32px' : '',
        backgroundSize: '50px 50px',
        backgroundPosition: 'center',
      }}
    >
      <div
        {...buttonProps}
        ref={ref}
        style={{
          fontFamily: 'Poppins',
          fontsize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '22px',
          color: isSelected ? '#FFFFFF' : '#FF6B4F',
          opacity: isDisabled ? 0.5 : 1,
          textAlign: 'center',
        }}
      >
        {formattedDate}
      </div>
    </TableCell>
  );
}
