import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { isMobile } from 'react-device-detect';
import QueryString from 'query-string';
import {
  CenterWrapper,
  Logo,
  EmailLogo,
  CircleWrapper,
  BigText,
  LoadingWrapper,
  Loading,
} from '../components';

import djangoLogo from '../../../images/djangoLogo.png';
import SET_COOKIE from '../../../components/graphQL/mutations/auth/setCookie.gql';
import VERIFY_CANDIDATE_EMAIL from './verifyCandidateEmail.gql';

const DEEP_LINK_REDIRECTION = 'joeapp://email/candidate/verified';

const VerifyCandidateEmail = ({ location: { search } }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const qs = QueryString.parse(search);

  const [verifyCandidateEmail] = useMutation(VERIFY_CANDIDATE_EMAIL, {
    onCompleted() {
      setLoading(false);
      if (isMobile) window.location.href = `${DEEP_LINK_REDIRECTION}?token=${qs.code}`;
    },
    onError() {
      setError(true);
      setLoading(false);
      if (isMobile) window.location.href = `${DEEP_LINK_REDIRECTION}?token=${qs.code}`;
    },
    onLoading() {
      setLoading(true);
    },
  });

  const [setCookie] = useMutation(SET_COOKIE, {
    onCompleted() {
      verifyCandidateEmail({
        variables: {
          candidateEmail: decodeURIComponent(qs.candidateEmail),
        },
      });
    },
  });

  useEffect(() => {
    setCookie({
      variables: {
        token: qs.token,
      },
    });
  }, [qs.token, setCookie]);

  return (
    <div>
      <CenterWrapper>
        <Logo src={djangoLogo} />
      </CenterWrapper>
      {loading && (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )}
      <CircleWrapper>
        {!loading && !error && (
          <>
            <BigText>Votre e-mail a été mis à jour</BigText>
            <EmailLogo src={'https://app-sandbox.getjoe.co/email.gif'} />
          </>
        )}
        {!loading && error && (
          <>
            <BigText>Une erreur est survenue</BigText>
            <EmailLogo src={'https://app-sandbox.getjoe.co/email.gif'} />
          </>
        )}
      </CircleWrapper>
    </div>
  );
};

export default VerifyCandidateEmail;
