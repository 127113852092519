import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import notchedOutline from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { CircularProgress, Tooltip } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Replay } from '@material-ui/icons';

import { FormattedMessage, injectIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { Query } from '@apollo/client/react/components';

import GET_CREDIT_BY_ID from './GetCreditById.gql';
import { getDateHour, showDueAt, showPaidAt } from '../../utils';
import { ChangeDueDateWizard } from './ChangeDueDateWizard';
import { formatPaymentStatus, formatPaymentType } from '../../Payments/components/PaymentsContainer';
import { RetryUnpaidInstallmentDialog } from './RetryUnpaidInstallmentDialog';
import { environment } from '../../../../environments/environment';

const DEFAULT_ROWS_PER_PAGE = 5;
const retryPaymentFeatureFlag = environment.RETRY_PAYMENT_FEATURE_FLAG;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'nbr', numeric: false, disablePadding: false, label: 'Numéro' },
  { id: 'dueAt', numeric: false, disablePadding: false, label: 'Dû le' },
  { id: 'paidAt', numeric: false, disablePadding: false, label: 'Payé le' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Montant' },
  { id: 'penaltiesAmount', numeric: false, disablePadding: false, label: 'Montant de la pénalité' },
  { id: 'state', numeric: false, disablePadding: false, label: 'Statut' },
  {
    id: 'bankAccountName',
    numeric: false,
    disablePadding: false,
    label: 'Reference',
  },
  { id: 'calendar', numeric: false, disablePadding: false, label: 'Quantième' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all payments' }}
            name="checkbox"
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
    width: '30%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="components.utils.payments" />
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  calendar: {
    cursor: 'pointer',
  },
  repaymentButton: {
    backgroundColor: '#FF6B4F',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    borderColor: '#FF6B4F',
    border: 'transparent',
    width: '24px',
    height: '24px',
    borderRadius: '6px',
  },
  paymentStatusCell: { minWidth: '150px' },
  paymentStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  replayIcon: {
    maxWidth: '20px',
    height: '20px',
  },
}));

const PaymentsContainer = ({ intl, creditId, borrowerId }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('nbr');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [payments, setPayments] = useState([]);
  const [showChangeDueDateWizard, setShowChangeDueDateWizard] = useState(false);
  const [installmentId, setInstallmentId] = useState('');
  const [initialDueAt, setInitialDueAt] = useState();
  const [noCalendar, setNoCalendar] = useState(false);
  const [dueAt, setDueAt] = useState(null);
  const [previousDueAt, setPreviousDueAt] = useState(null);
  const [number, setNumber] = useState();
  const [fetchPolicy, setFetchPolicy] = useState('cache-and-network');
  const [retryUnpaidInstallment, setRetryUnpaidInstallment] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const refetchRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, payments) => {
    if (event.target.checked) {
      const newSelecteds = payments.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const showNumber = row => {
    let numbers = [];
    if (row.type === 'DISBURSEMENT' || row.type === 'FEES') {
      return '0';
    }
    if (row.type === 'debt') {
      stableSort(payments, getComparator(order, orderBy)).forEach(line => {
        if (row.id === line.regularizeId) numbers.push(line.nbr);
      });
    }
    if (numbers.length > 0) return numbers.toString();
    return row.nbr?.toString();
  };

  const handleClickOpen = row => {
    setShowChangeDueDateWizard(!showChangeDueDateWizard);
    setNumber(row?.nbr);
    setDueAt(row?.dueAt);
    setInstallmentId(row?.id);
    setInitialDueAt(row?.initialDueAt);
    findPreviousRepayment(row?.nbr);
  };

  const handleNoCalendar = () => {
    let found = payments?.find(repayment => repayment.status === 'FAILED');
    if (found) return setNoCalendar(false);
  };

  const findPreviousRepayment = nbr => {
    let found = payments?.find(repayment => repayment.nbr === nbr - 1 && repayment.type === 'INSTALLMENT');
    if (found) return setPreviousDueAt(found?.dueAt);
  };

  const handleShowCalendar = row => {
    if (
      (row?.state === 'REQUESTED' || row?.state === 'SCHEDULED') &&
      row.type !== 'FEES' &&
      !row.type.includes('REGULARIZATION') &&
      row?.id
    )
      return <DateRangeIcon className={classes.calendar} onClick={() => handleClickOpen(row)} />;
  };

  const handleCloseModal = () => {
    setShowChangeDueDateWizard(false);
    setFetchPolicy('network-only');
  };
  const handleCloseRetryPayment = () => {
    if (refetchRef.current) {
      refetchRef.current();
    }
    setRetryUnpaidInstallment(false);
  };

  if (creditId)
    return (
      <div className={classes.root}>
        <RetryUnpaidInstallmentDialog
          open={retryUnpaidInstallment}
          onClose={handleCloseRetryPayment}
          selectedTransaction={selectedTransaction}
          borrowerId={borrowerId}
          fromLoans
        />
        <Paper className={classes.paper}>
          <Query
            query={GET_CREDIT_BY_ID}
            fetchPolicy={fetchPolicy}
            variables={{
              creditId: creditId,
            }}
          >
            {({ data, error, loading, refetch }) => {
              refetchRef.current = refetch;
              if (loading) return <CircularProgress />;
              if (error) {
                if (error.networkError) {
                  toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  });
                } else {
                  toast.error(
                    intl.formatMessage({
                      id: 'components.utils.errorHasOccured',
                    }) + error.message,
                    {
                      position: 'bottom-center',
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: false,
                    },
                  );
                }
                return '';
              }

              if (data?.getCreditById?.transactions) setPayments(data.getCreditById.transactions);
              if (data?.getCreditById?.transactions.length) setCount(data.getCreditById.transactions.length);

              return (
                <>
                  <EnhancedTableToolbar
                    numSelected={selected.length}
                    payments={payments}
                    selected={selected}
                    setSelected={setSelected}
                    refetch={refetch}
                  />
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={dense ? 'small' : 'medium'}
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={event => handleSelectAllClick(event, payments)}
                        onRequestSort={handleRequestSort}
                        rowCount={payments.length}
                      />
                      <TableBody>
                        {stableSort(payments, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                onClick={event => handleClick(event, row.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.id}
                                selected={isItemSelected}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    name="checkbox"
                                  />
                                </TableCell>
                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                  {formatPaymentType(row.type)}
                                </TableCell>
                                <TableCell align="left">{showNumber(row)}</TableCell>
                                <TableCell align="left">
                                  <Tooltip title={getDateHour(row.dueAt)}>
                                    <div>{showDueAt(row)}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="left">
                                  <Tooltip title={getDateHour(row.paidAt)}>
                                    <div>{showPaidAt(row)}</div>
                                  </Tooltip>
                                </TableCell>
                                <TableCell align="left">{row.principalAmount?.toString() || '0'}€</TableCell>
                                <TableCell align="left">{row.penaltyAmount?.toString() || '0'}€</TableCell>
                                <TableCell align="left" className={classes.paymentStatusCell}>
                                  <div className={classes.paymentStatus}>
                                    <div>{formatPaymentStatus(row.state)}</div>
                                    {retryPaymentFeatureFlag === 'ON' && row.state === 'UNPAID' && (
                                      <button
                                        className={classes.repaymentButton}
                                        onClick={event => {
                                          event.stopPropagation();
                                          setSelectedTransaction(row);
                                          setRetryUnpaidInstallment(e => !e);
                                        }}
                                      >
                                        <Replay className={classes.replayIcon} />
                                      </button>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell align="left">{row.reference}</TableCell>
                                <TableCell align="left">
                                  {handleNoCalendar()}
                                  {!noCalendar && handleShowCalendar(row)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              );
            }}
          </Query>
        </Paper>
        <FormControlLabel
          name="switch"
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Affichage recentré"
        />
        {showChangeDueDateWizard && (
          <ChangeDueDateWizard
            open={showChangeDueDateWizard}
            handleClose={() => {
              handleCloseModal();
            }}
            dueAt={dueAt}
            creditId={creditId}
            installmentId={installmentId}
            initialDueAt={initialDueAt}
            previousDueAt={previousDueAt}
            number={number}
          />
        )}
      </div>
    );
  return null;
};

export default injectIntl(PaymentsContainer);
