import React from 'react';

// Router imports
import { Switch, Route, Redirect } from 'react-router-dom';

// Utilities imports
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Custom components imports
import Django from './Django';
import VerifyEmail from './Django/VerifyEmail';
import VerifyCandidateEmail from './Django/VerifyCandidateEmail';
import GetApp from './Django/GetApp';
import Referrals from './Django/Referrals';
import BankAccountReconnexion from './Django/BankAccountReconnexion';
import SignInRoute from './Auth/SignIn';
import NotFoundRoute from './common/NotFound';
import ProtectedRoute from './common/ProtectedRoute';
import DashboardLayout from './Dashboard/Layout';
import ShortLinkRedirect from './ShortLinkRedirect';

// GQL imports
import ReconnectBankAccount from './Django/ReconnectBankAccount';
import LinkBankAccount from './Django/LinkBankAccount';

import ME from '../components/graphQL/queries/Me.gql';
import { useQuery } from '@apollo/client';
import UserLoggedInContext from './UserLoggedInContext';
import { useStore } from '../hooks/useStore';

toast.configure({ autoClose: 1000 });

const MainRouter = ({ client }) => {
  const { isLoggedIn, set: setIsLoggedIn } = useStore();
  useQuery(ME, {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted() {
      setIsLoggedIn(true);
    },
    onError(err) {
      setIsLoggedIn(false);
    },
  });

  return (
    <UserLoggedInContext.Provider value={[isLoggedIn, setIsLoggedIn]}>
      <Switch>
        {/* PROTECTED DASHBOARD ROUTE */}
        <ProtectedRoute
          path="/dashboard"
          client={client}
          render={props => <DashboardLayout client={client} {...props} />}
        />

        {/* BLOCKED ACCOUNT ROUTE */}

        <Route path="/signin/blocked" render={props => <SignInRoute blocked {...props} />} />

        {/* WRONG BROWSER ROUTE */}

        <Route path="/signin/changeBrowser" render={props => <SignInRoute wrongBrowser {...props} />} />

        {/* SIGNIN ROUTE */}
        <Route path="/signin" render={props => <SignInRoute {...props} />} />

        <Redirect from="/create" to="/signup/register" />

        {/* SHORTENED UPLOAD ROUTE */}

        <Route path="/s/:hash" render={props => <ShortLinkRedirect hash={props.match.params.hash} />} />

        {/* DASHBOARD REDIRECT ROUTE */}

        <Redirect from="/dashboard/home" to="/" />

        {/* Django */}

        <Route
          path="/email/verified"
          client={client}
          render={props => <VerifyEmail client={client} {...props} />}
        />

        <Route
          path="/email/candidate/verified"
          client={client}
          render={props => <VerifyCandidateEmail client={client} {...props} />}
        />

        <Route
          path="/referrals/:referralCode"
          client={client}
          render={props => <Referrals client={client} {...props} />}
        />

        <Route path="/get-app" client={client} render={props => <GetApp client={client} {...props} />} />

        <Route
          path="/bankaccount/reconnexion"
          client={client}
          render={props => <BankAccountReconnexion client={client} {...props} />}
        />
        <Route
          path="/email/verified"
          client={client}
          render={props => <VerifyEmail client={client} {...props} />}
        />

        <Route path="/get-app" client={client} render={props => <GetApp client={client} {...props} />} />

        <Route
          path="/bankaccount/reconnexion"
          client={client}
          render={props => <BankAccountReconnexion client={client} {...props} />}
        />

        <Route
          path="/LinkBankAccount"
          client={client}
          render={props => <LinkBankAccount {...props} />}
        ></Route>
        <Route
          path="/ReconnectBankAccount"
          client={client}
          render={props => <ReconnectBankAccount {...props} />}
        ></Route>

        {/* CATCH ALL ROOT ROUTE >> GOES TO SIGNIN */}

        <Route exact path="/" render={props => <Django {...props} />} />

        {/* 404 NOT FOUND ROUTE */}

        <Route component={NotFoundRoute} />
      </Switch>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
        style={{ position: 'fixed', bottom: 0, right: 0 }}
      />
    </UserLoggedInContext.Provider>
  );
};
export default MainRouter;
