import styled, { keyframes } from 'styled-components';

export const CenterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify: center;
  flex-direction: column;
  @media (max-width: 675px) {
    padding-right: 0.5em;
  }
`;

export const PhoneWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

export const LogoWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  width: 280px;
  height: 55px;
  background-color: #19cff1;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  border: 0px;
`;

export const ButtonText = styled.h1`
  color: white;
  font-size: 15px;
  @media (max-width: 675px) {
    font-size: 15px;
  }
  display: flex;
  align-items: center;
  text-align: center;
`;

export const TextResponse = styled.h1`
  color: ${props => props.color};
  width: 280px;
  font-size: 15px;
  @media (max-width: 675px) {
    font-size: 15px;
  }
  display: flex;
  align-items: center;
  text-align: center;
`;

export const CircleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 675px) {
    padding-top: 5em;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 10em;
  @media (max-width: 675px) {
    padding-top: 5em;
  }
`;

export const Text = styled.h1`
  width: 85%;
  color: black;
  font-size: 50px;
  font-weight: 50px;
  @media (max-width: 675px) {
    font-size: 30px;
  }
  text-align: center;
`;

export const Subtitle = styled.h3`
  width: 85%;
  color: #9e9e9e;
  font-size: 30px;
  @media (max-width: 675px) {
    font-size: 18px;
  }
  text-align: center;
`;

export const BigText = styled.h1`
  display: flex;
  width: 100%;
  height: 100%;
  color: black;
  font-size: 50px;
  @media (max-width: 675px) {
    font-size: 30px;
  }
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 2em;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Circle = styled.div`
  height: 600px;
  width: 600px;
  @media (max-width: 675px) {
    height: 300px;
    width: 300px;
  }
  background-image: linear-gradient(#00f19d, #19cff1);
  border-radius: 50%;
  align-items: center;
  display: inline-block;
`;

export const Logo = styled.img`
  width: 150px;
  height: 150px;
`;

export const EmailLogo = styled.img`
  width: 200px;
  height: 150px;
`;

export const Icon = styled.img`
  width: 120px;
  height: 120px;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  align-items: center;
  border-top: 2px solid #00f19d;
  border-right: 2px solid #00f19d;
  border-bottom: 2px solid #00f19d;
  border-left: 4px solid #19cff1;
  background: transparent;
  width: ${props => (props.width ? `${props.width}px` : '150px')};
  height: ${props => (props.height ? `${props.height}px` : '150px')};
  border-radius: 50%;
`;
