import { useCalendar, useCalendarCell, useCalendarGrid, useLocale } from 'react-aria';
import { useCalendarState } from 'react-stately';
import { createCalendar, getWeeksInMonth } from '@internationalized/date';
import {
  Button,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableBody,
} from '@material-ui/core';
import React from 'react';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

/**
 *
 * @param {import("react-stately").CalendarStateOptions} props
 * @returns
 */
export function Calendar(props) {
  let { locale } = useLocale();
  let state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    minValue: props.minValue,
    maxValue: props.maxValue,
  });
  let { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);
  return (
    <Grid
      {...calendarProps}
      container
      direction="column"
      style={{
        borderRadius: '32px',
        backgrund: '#FFF',
        border: '2px solid #000',
      }}
    >
      <Grid item style={{ padding: '8px 0px' }}>
        <Grid container direction="row" alignItems="center" justifyContent="center">
          <Grid item>
            <Button {...prevButtonProps} onClick={prevButtonProps.onPress}>
              <ChevronLeft style={{ width: 24, height: 24, color: '#000' }} />
            </Button>
          </Grid>
          <Grid item>
            <Typography
              style={{
                fontFamily: 'Poppins',
                fontsize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '22px',
                color: '#000',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Button {...nextButtonProps} onClick={nextButtonProps.onPress}>
              <ChevronRight style={{ width: 24, height: 24, color: '#000' }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <CalendarGrid state={state} />
    </Grid>
  );
}

function CalendarGrid({ state, ...props }) {
  let { locale } = useLocale();
  let { gridProps, headerProps, weekDays } = useCalendarGrid({ ...props, weekdayStyle: 'long' }, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  let weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  return (
    <TableContainer>
      <Table {...gridProps} style={{ tableLayout: 'fixed' }}>
        <TableHead {...headerProps}>
          <TableRow>
            {weekDays.map(day => (
              <TableCell
                key={day}
                style={{
                  fontFamily: 'Poppins',
                  fontsize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '22px',
                  color: '#000',
                  textTransform: 'capitalize',
                  textAlign: 'center',
                  borderColor: '#000',
                  borderWidth: 2,
                }}
              >
                {day}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...new Array(weeksInMonth).keys()].map(weekIndex => (
            <TableRow key={weekIndex}>
              {state.getDatesInWeek(weekIndex).map((date, i) =>
                date ? (
                  <CalendarCell key={i} state={state} date={date} last={weekIndex === weeksInMonth - 1} />
                ) : (
                  <TableCell
                    key={i}
                    style={{
                      fontFamily: 'Poppins',
                      fontsize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '22px',
                      color: '#FF6B4F',
                      borderColor: '#FF6B4F',
                    }}
                  />
                ),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CalendarCell({ state, date, last }) {
  let ref = React.useRef(null);
  let { cellProps, buttonProps, isSelected, isDisabled, isOutsideVisibleRange, formattedDate } =
    useCalendarCell({ date }, state, ref);
  return (
    <TableCell
      {...cellProps}
      style={{
        borderColor: !last ? '#000000' : 'transparent',
        borderWidth: 2,
        backgroundColor: isSelected ? '#FF6B4F' : 'transparent',
      }}
    >
      <div
        {...buttonProps}
        ref={ref}
        hidden={isOutsideVisibleRange}
        style={{
          fontFamily: 'Poppins',
          fontsize: '14px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: '22px',
          color: isSelected ? '#FFFFFF' : isDisabled ? '#e7e7e7' : '#000000',
          textAlign: 'center',
        }}
      >
        {formattedDate}
      </div>
    </TableCell>
  );
}
