import { InMemoryCache, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { GRAPHQL_URI } from './ServerUri';
import { onError } from '@apollo/client/link/error';
import { useStore } from '../hooks/useStore';

const HTTP_HEADER_PLATFORM = 'X-joe-platform';
const PLATFORM = 'web-app';

// Create an http link:
const httpLink = createUploadLink({
  uri: `${GRAPHQL_URI}`,
  credentials: 'include',
});
console.log(`${GRAPHQL_URI}`);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from the URL (if it exists)
  const platformHeader = { [HTTP_HEADER_PLATFORM]: PLATFORM };
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      ...platformHeader,
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(error => {
      if (error.message === 'Unauthenticated request on protected resolver') {
        useStore.setState({ isLoggedIn: false });
      }
    });
  }
});

const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default apolloClient;
