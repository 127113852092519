import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import * as serviceWorker from './serviceWorker';
import { translationMessages, DEFAULT_LOCALE } from './i18n';

const MOUNT_NODE = 'root';
ReactDOM.render(
  <App messages={translationMessages} defaultLocale={DEFAULT_LOCALE} />,
  document.getElementById(MOUNT_NODE),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
