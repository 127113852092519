/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import notchedOutline from '@material-ui/core/OutlinedInput';

import {
  Typography,
  FormControl,
  IconButton,
  TextField,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog,
  InputAdornment,
  Grid,
} from '@material-ui/core';

import { FormattedMessage, injectIntl } from 'react-intl';

import { toast } from 'react-toastify';
import Axios from 'axios';
import { print } from 'graphql';

import StandardButton from '../../../../components/common/StandardButton';
import CommonStyles from '../../../../styles/common';

import UPDATE_PWD from '../../../../components/graphQL/mutations/auth/updatePassword.gql';
import { GRAPHQL_URI } from '../../../../graphql/ServerUri';

const httpUri = GRAPHQL_URI;

const styles = theme => ({
  ...CommonStyles(theme),
  accountTitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    fontWeight: '400',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  accountSubtitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    color: theme.palette.disabled,
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssLabelSmall: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
    fontSize: '0.9rem',
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  notchedOutline: {},
  newPasswordInput: {
    flexGrow: 1,
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  newPasswordConfirmInput: {
    flexGrow: 1,
    marginRight: '0.5em',
    marginLeft: '0.5em',
    marginTop: '1em',
  },
  iconColorActive: {
    color: theme.palette.lightBlue,
  },
  iconColorInactive: {
    color: theme.palette.inactive,
  },
  passwordInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'contents',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'inherit',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
});

// eslint-disable-next-line arrow-body-style
const AccountPassword = ({ classes, user, session, intl, windowSize }) => {
  const [open, setOpen] = useState(false);
  const [oldPassword, setoldPassword] = useState('');
  const [newPassword, setnewPassword] = useState('');
  const [newPasswordConfirm, setnewPasswordConfirm] = useState('');
  const [errors, setErrors] = useState('');
  const [revealOldPassword, setrevealOldPassword] = useState(false);
  const [revealNewPassword, setrevealNewPassword] = useState(false);
  const [revealNewConfirmPassword, setrevealNewConfirmPassword] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const handleShowOldPassword = useCallback(() => {
    setrevealOldPassword(!revealOldPassword);
  }, [revealOldPassword]);

  const handleShowNewPassword = useCallback(() => {
    setrevealNewPassword(!revealNewPassword);
  }, [revealNewPassword]);

  const handleShowNewConfirmPassword = useCallback(() => {
    setrevealNewConfirmPassword(!revealNewConfirmPassword);
  }, [revealNewConfirmPassword]);

  const handleChangePassword = useCallback(() => {
    const updatePassword = () => {
      const result = Axios.post(httpUri, {
        query: print(UPDATE_PWD),
        variables: { oldPassword, newPassword },
      })
        .then(resultUpdate => {
          if (!resultUpdate.data.errors) {
            setServerMessage('');
            setOpen(true);
          } else {
            setServerMessage(resultUpdate.data.errors[0].message);
            setOpen(true);
          }
        })
        .catch(error => {
          if (error && error.networkError) {
            toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          } else {
            toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error.message, {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
        });
      return result;
    };

    if (newPassword !== newPasswordConfirm) {
      setOpen(true);
    } else if (user != null) {
      updatePassword();
    }
  }, [newPassword, newPasswordConfirm, oldPassword, user, intl]);

  const handleInputChange = e => {
    if (!e || typeof e === 'undefined') {
      return;
    }

    if (e.target.name === 'oldPassword') {
      setoldPassword(e.target.value);
    } else if (e.target.name === 'newPassword') {
      setnewPassword(e.target.value);
    } else if (e.target.name === 'newConfirmPassword') {
      setnewPasswordConfirm(e.target.value);
    }
  };

  return (
    <Paper elevation={0} className={classes.cardContainer}>
      <Typography variant="h1" className={classes.accountTitle}>
        <FormattedMessage id="components.session.myAccountPassword" />
      </Typography>
      <Typography className={classes.accountSubtitle}>
        <FormattedMessage id="components.session.myAccountPasswordUpdate" />
      </Typography>
      <FormControl margin="normal" required fullWidth autoComplete="off">
        <TextField
          onChange={handleInputChange}
          error={!!errors.password}
          helperText={errors.password ? <FormattedMessage id="components.utils.passwordError" /> : null}
          type={revealOldPassword ? 'text' : 'password'}
          name="oldPassword"
          className={classes.passwordInput}
          InputLabelProps={{
            classes: {
              root: windowSize.innerWidth < 375 ? classes.cssLabelSmall : classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={() => handleShowOldPassword()}
                  className={revealOldPassword ? classes.iconColorActive : classes.iconColorInactive}
                >
                  {revealOldPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          label={<FormattedMessage id="components.myaccount.oldpwd" />}
          variant="outlined"
          id="custom-css-outlined-input"
        />
        <Grid container justify="space-between">
          <TextField
            onChange={handleInputChange}
            error={!!errors.password}
            helperText={errors.password ? <FormattedMessage id="components.utils.passwordError" /> : null}
            type={revealNewPassword ? 'text' : 'password'}
            name="newPassword"
            className={classes.newPasswordInput}
            InputLabelProps={{
              classes: {
                root: windowSize.innerWidth < 375 ? classes.cssLabelSmall : classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => handleShowNewPassword()}
                    className={revealNewPassword ? classes.iconColorActive : classes.iconColorInactive}
                  >
                    {revealNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={<FormattedMessage id="components.myaccount.newpwd" />}
            variant="outlined"
            id="custom-css-outlined-input"
          />
          <TextField
            onChange={handleInputChange}
            error={!!errors.password}
            helperText={errors.password ? <FormattedMessage id="components.utils.passwordError" /> : null}
            type={revealNewConfirmPassword ? 'text' : 'password'}
            name="newConfirmPassword"
            className={classes.newPasswordConfirmInput}
            InputLabelProps={{
              classes: {
                root: windowSize.innerWidth < 375 ? classes.cssLabelSmall : classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => handleShowNewConfirmPassword()}
                    className={revealNewConfirmPassword ? classes.iconColorActive : classes.iconColorInactive}
                  >
                    {revealNewConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={<FormattedMessage id="components.myaccount.newpwdConfirm" />}
            variant="outlined"
            id="custom-css-outlined-input"
          />
        </Grid>

        <div>
          {
            <StandardButton
              onClick={() => handleChangePassword()}
              customComponentStyling={{
                maxWidth: '50%',
                minWidth: '18%',
                marginRight: '0.5em',
                alignSelf: 'center',
                marginTop: '1em',
                marginBottom: '0',
                float: 'right',
              }}
            >
              <Typography variant="button">
                <FormattedMessage id="components.session.save" />
              </Typography>
            </StandardButton>
          }
        </div>
        <Dialog
          open={open}
          keepMounted
          onBackdropClick={() => setOpen(false)}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {newPassword !== newPasswordConfirm ? (
              <FormattedMessage id="components.myaccount.passwordsDifferent" />
            ) : serverMessage === '' ? (
              <FormattedMessage id="components.myaccount.passwordUpdated" />
            ) : (
              <span> An error has occured... </span>
            )}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {newPassword !== newPasswordConfirm ? (
                <FormattedMessage id="components.myaccount.passwordsDifferentDesc" />
              ) : serverMessage === '' ? (
                <FormattedMessage id="components.myaccount.passwordUpdatedDesc" />
              ) : (
                <span> {serverMessage} </span>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </Paper>
  );
};
export default injectIntl(withStyles(styles)(AccountPassword));

AccountPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  user: PropTypes.object,
};
