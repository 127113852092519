/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage, injectIntl } from 'react-intl';

// Material UI imports
import {
  Grid,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import { toast } from 'react-toastify';

import withStyles from '@material-ui/core/styles/withStyles';

// i18n imports
import Axios from 'axios';
import { print } from 'graphql';
import CommonStyles from '../../../styles/common';

import UPDATE_USER from '../../../components/graphQL/mutations/user/update.gql';

import AccountSecurity from './components/AccountSecurity';
import AccountPassword from './components/AccountPassword';
import AccountNotifications from './components/AccountNotifications';
import AccountDetails from './components/AccountDetails';

import DashboardContainer from '../DashboardContainer';
import Loading from '../../../components/common/Loading';
import UserContext from '../UserContext';
import { GRAPHQL_URI } from '../../../graphql/ServerUri';

const httpUri = GRAPHQL_URI;

// Component specific styling
const styles = theme => ({
  ...CommonStyles(theme),
  accountTitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    fontWeight: '400',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  accountSubtitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    color: theme.palette.disabled,
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'contents',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'inherit',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const MyAccount = ({ classes, history, windowSize, client, intl }) => {
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [serverStatus, setserverStatus] = useState('');
  const curUser = useContext(UserContext);
  const toastOptions = {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  // eslint-disable-next-line consistent-return
  const handleUpdateNotifications = async () => {
    if (user != null) {
      const result = await Axios.post(httpUri, {
        query: print(UPDATE_USER),
        variables: {
          id: user.id,
          newsletter: user.newsletter,
          notificationTransactionPhysical: user.notificationTransactionPhysical,
          notificationTransactionDeclined: user.notificationTransactionDeclined,
          communicationChannel: user.communicationChannel,
          // eslint-disable-next-line object-shorthand
          // media: media
        },
      })
        .then(resultUser => {
          setserverStatus(resultUser.status);
          setOpen(true);
          if (resultUser.status === 200) {
            toast.success(
              intl.formatMessage({
                id: 'components.myaccount.savedDialogTitle',
              }),
              toastOptions,
            );
            return true;
          }
          return false;
        })
        .catch(error => {
          if (error && error.networkError) {
            toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), toastOptions);
          } else {
            toast.error(
              intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error.message,
              toastOptions,
            );
          }
        });
      return result;
    }
  };

  const handleToggleChange = (e, value) => {
    if (value === 'none') {
      setUser({ ...user, communicationChannel: 'none' });
    } else {
      setUser({ ...user, communicationChannel: 'sms' });
    }
  };

  const handleInputChange = e => {
    if (!e || typeof e === 'undefined') {
      return;
    }

    const { name, value } = e.target;

    if (e.target.type === 'checkbox') {
      setUser({ ...user, [name]: e.target.checked });
    } else if (e.target.type === 'ToggleButtonGroup') {
      console.log('e.target.type', e.target.type);
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  useEffect(() => {
    setUser(curUser);
  }, [curUser]);

  const leftPanel = [
    {
      id: 'AccountDetails',
      component: (
        <AccountDetails
          user={user}
          setUser={setUser}
          windowSize={windowSize}
          classes={classes}
          history={history}
          key="AccountDetails"
        />
      ),
    },
    {
      id: 'AccountPassword',
      component: (
        <AccountPassword user={user} windowSize={windowSize} classes={classes} key="AccountPassword" />
      ),
    },
  ];

  const rightPanel = [
    {
      id: 'AccountNotifications',
      component: (
        <AccountNotifications
          handleInputChange={handleInputChange}
          handleToggleChange={handleToggleChange}
          handleSaveNotifications={handleUpdateNotifications}
          user={user}
          classes={classes}
          history={history}
          key="AccountNotifications"
        />
      ),
    },
    {
      id: 'AccountSecurity',
      component: (
        <AccountSecurity
          client={client}
          user={user}
          classes={classes}
          history={history}
          key="AccountSecurity"
        />
      ),
    },
  ];

  if (!curUser)
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Loading />
      </Grid>
    );

  return (
    <Grid item xs={12}>
      <DashboardContainer
        history={history}
        windowSize={windowSize}
        panelStyle="largerLeftSide"
        displayWarnin
        leftPanel={leftPanel}
        rightPanel={rightPanel}
      />
      <Dialog
        open={open}
        keepMounted
        onBackdropClick={() => setOpen(false)}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {serverStatus === 200 ? (
            <FormattedMessage id="components.myaccount.savedDialogTitle" />
          ) : (
            <FormattedMessage id="components.utils.error" />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {serverStatus === 200 ? (
              <FormattedMessage id="components.myaccount.savedDialogDesc" />
            ) : (
              <FormattedMessage id="components.utils.errorDesc" />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default injectIntl(withStyles(styles)(MyAccount));

MyAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  windowSize: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};
