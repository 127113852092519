import React from 'react';
import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import CommonStyles from '../../styles/common';

const styles = theme => ({
  ...CommonStyles(theme),
  checkboxStyle: {
    color: 'black',
  },
});

const StandardCheckbox = props => {
  const {
    classes: { checkboxStyle },
    ...rest
  } = props;

  return <Checkbox className={checkboxStyle} checkedIcon={<CheckBoxOutlined />} color="default" {...rest} />;
};

StandardCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StandardCheckbox);
