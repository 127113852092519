import { Toolbar, Tooltip, IconButton, Typography, makeStyles, lighten } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Refresh } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import REFRESH_FICP_INFORMATION from '../refreshFICPInformation.gql';
import GET_FICP_DATAS from '../getFicpDatas.gql';
const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
    width: '30%',
  },
}));
export const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, filter, setFilter, userId } = props;
  const [refreshFICPInformation] = useMutation(REFRESH_FICP_INFORMATION, {
    variables: { userId },
    refetchQueries: [{ query: GET_FICP_DATAS, variables: { userId } }],
  });
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Tooltip title="Filtre">
        <IconButton aria-label="Filtre" onClick={() => setFilter(!filter)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="components.utils.ficp" />
        </Typography>
      )}
      <IconButton
        onClick={refreshFICPInformation}
        style={{
          backgroundColor: '#FFF0ED',
          color: '#FF6B4F',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '9.6px',
        }}
      >
        <Refresh></Refresh>
      </IconButton>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
};
