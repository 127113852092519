/* eslint-disable no-nested-ternary */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

import withStyles from '@material-ui/core/styles/withStyles';

import { Typography, FormControlLabel } from '@material-ui/core';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { FormattedMessage } from 'react-intl';
import StandardButton from '../../../../components/common/StandardButton';
import StandardCheckbox from '../../../../components/common/StandardCheckbox';
import CommonStyles from '../../../../styles/common';

const styles = theme => ({
  ...CommonStyles(theme),
  accountTitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    fontWeight: '400',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  label: {
    fontSize: '1.01rem',
    textAlign: 'initial',
  },
  checkboxStyle: {
    color: 'black',
  },
  unclickedToggle: {
    minWidth: '50%',
    maxWidth: '50%',
    color: '#000000',
    textColor: '#000000',
    borderColor: theme.palette.inactive,
    borderRadius: theme.custom.borderRadius,
    padding: 0,
    border: '1px solid',
    // big button styling
    height: 58,
    [theme.breakpoints.between('xs', 'sm')]: {
      // small button styling
      height: 46,
    },
  },
  clickedText: {
    color: '#FFFFFF',
    backgroundColor: '#FFFFFF',
    textColor: '#FFFFFF',
  },
  unclickedText: {
    color: '#000000',
    backgroundColor: '#000000',
    textColor: '#000000',
  },
  clickedToggle: {
    minWidth: '50%',
    maxWidth: '50%',
    color: '#FFFFFF !important',
    backgroundColor: '#FFFFFF',
    textColor: '#FFFFFF',
    background: theme.palette.mainGradient,
    borderRadius: theme.custom.borderRadius,
    border: 0,
    // border: 0,
    // big button styling
    height: 58,
    padding: 0,
    [theme.breakpoints.between('xs', 'sm')]: {
      // small button styling
      height: 46,
    },
  },
  divCheckboxes: {
    display: 'grid',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1em',
  },
  toggleContainer: {
    height: 56,
    display: 'flex',
    marginTop: '1em',
    alignItems: 'center',
    justifyContent: 'center',
    margin: `${theme.spacing(2)}px 0`,
  },
  accountDesc: {
    marginTop: '1em',
    fontSize: '1.01rem',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  accountSubtitle: {
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: '1.01rem',
    color: theme.palette.disabled,
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  cardContainerTop: {
    marginTop: '8px',
    marginBottom: '8px',
    borderRadius: '0.25em',
    filter: 'drop-shadow(0 0 0.5rem #EBEBEB)',
    padding: '20px 10px 20px 10px',
    backgroundColor: 'white',
    width: '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'contents',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'inherit',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
  standardButtonStyle: {
    borderRadius: theme.custom.borderRadius,
    boxShadow: 'none',
    // big button styling
    height: 58,
    minWidth: '100%',
    maxWidth: '100%',

    [theme.breakpoints.between('xs', 'sm')]: {
      // small button styling
      height: 46,
      minWidth: 220,
    },
  },
});

const AccountNotifications = ({
  classes,
  user,
  handleInputChange,
  handleSaveNotifications,
  handleToggleChange,
}) => {
  return (
    <Paper elevation={0} className={classes.cardContainer}>
      <Typography variant="h1" className={classes.accountTitle}>
        <FormattedMessage id="components.myaccount.notifications" />
      </Typography>

      <Typography className={classes.accountSubtitle}>
        <FormattedMessage id="components.myaccount.notificationMedia" />
      </Typography>

      <Typography className={classes.accountDesc}>
        <FormattedMessage id="components.myaccount.notificationMediaDesc" />
      </Typography>
      <div className={classes.toggleContainer}>
        <ToggleButtonGroup
          exclusive
          value={user && user.communicationChannel}
          type="radio"
          name="options"
          defaultValue="sms"
          className={classes.standardButtonStyle}
          onChange={handleToggleChange}
        >
          <ToggleButton
            value="sms"
            className={
              !user
                ? `${classes.unclickedToggle}`
                : user.communicationChannel === 'sms'
                ? `${classes.clickedToggle}`
                : `${classes.unclickedToggle}`
            }
          >
            <FormattedMessage
              className={
                !user
                  ? `${classes.unclickedText}`
                  : user.communicationChannel === 'sms'
                  ? `${classes.clickedText}`
                  : `${classes.unclickedText}`
              }
              id="components.myaccount.notificationMediaSms"
            />
          </ToggleButton>
          {/* Optionnal WhatsApp notifications toggleButton (unabled by default)
        <ToggleButton value="whatsapp" className={!user ? `${classes.unclickedToggle}` : (user.communicationChannel === 'whatsapp' ? `${classes.clickedToggle}` : `${classes.unclickedToggle}`)}>
          <FormattedMessage className={!user ? `${classes.unclickedText}` : (user.communicationChannel === 'whatsapp' ? `${classes.clickedText}` : `${classes.unclickedText}`)} id="components.myaccount.notificationMediaWhatsapp" />
        </ToggleButton>
        */}
          <ToggleButton
            value="none"
            className={
              !user
                ? `${classes.unclickedToggle}`
                : user.communicationChannel === 'none'
                ? `${classes.clickedToggle}`
                : `${classes.unclickedToggle}`
            }
          >
            <FormattedMessage
              className={
                !user
                  ? `${classes.unclickedText}`
                  : user.communicationChannel === 'none'
                  ? `${classes.clickedText}`
                  : `${classes.unclickedText}`
              }
              id="components.myaccount.notificationMediaNone"
            />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>

      <Typography className={classes.accountSubtitle}>
        <FormattedMessage id="components.myaccount.notificationRules" />
      </Typography>

      <div className={classes.divCheckboxes}>
        <FormControlLabel
          control={
            <StandardCheckbox
              checked={user && user.notificationTransactionPhysical}
              onChange={handleInputChange}
              value="true"
              name="notificationTransactionPhysical"
            />
          }
          classes={{
            label: classes.label,
          }}
          label={<FormattedMessage id="components.myaccount.notificationTransactionPhysical" />}
        />
        <FormControlLabel
          control={
            <StandardCheckbox
              checked={user && user.notificationTransactionDeclined}
              onChange={handleInputChange}
              value="true"
              name="notificationTransactionDeclined"
            />
          }
          classes={{
            label: classes.label,
          }}
          label={<FormattedMessage id="components.myaccount.notificationTransactionDeclined" />}
        />
        <FormControlLabel
          control={
            <StandardCheckbox
              checked={user && user.newsletter}
              onChange={handleInputChange}
              value="true"
              name="newsletter"
            />
          }
          classes={{
            label: classes.label,
          }}
          label={<FormattedMessage id="components.myaccount.newsletter" />}
        />
      </div>
      <div className={classes.buttonContainer}>
        <StandardButton
          onClick={() => handleSaveNotifications()}
          customComponentStyling={{
            maxWidth: '80%',
            minWidth: '150px',
            marginBottom: '0',
          }}
        >
          <Typography variant="button">
            <FormattedMessage id="components.session.apply" />
          </Typography>
        </StandardButton>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(AccountNotifications);

AccountNotifications.defaultProps = {
  user: null,
  handleInputChange: null,
  handleToggleChange: null,
};

AccountNotifications.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  handleSaveNotifications: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func,
  handleToggleChange: PropTypes.func,
};
