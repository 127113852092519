import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, makeStyles, CircularProgress } from '@material-ui/core';
import ChevronCard from './ChevronCard';
import { RadioButtonGroup } from './RadioButton';
import { useQuery, useMutation } from '@apollo/client';
import GET_PAYMENT_METHODS_BY_USER_ID from './GetPaymentMethodsByUserId.gql';
import RETRY_UNPAID_INSTALLMENT_PAYMENT from './RetryUnpaidInstallmentPayment.gql';
import { RetryUnpaidInstallmentResultDialog } from './RetryUnpaidInstallmentResultDialog';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#081433',
    backgroundColor: 'transparent',
  },
  continueButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#fff',
    borderColor: '#FF6B4F',
    backgroundColor: '#FF6B4F',
  },
  endButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#fff',
    borderColor: '#FF6B4F',
    backgroundColor: '#FF6B4F',
  },
  title: {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 800,
    color: '#081433',
    lineHeight: '38.4px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '23px',
    fontWeight: 500,
    color: '#081433',
    lineHeight: '27.6px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
  checkIcon: {
    color: '#FF6B4F',
    width: '50px',
    height: '50px',
    marginTop: '16px',
  },
  actionButton: { paddingTop: '16px', paddingBottom: '16px' },
  loading: { color: '#FF6B4F' },
}));

export const RetryUnpaidInstallmentDialog = ({
  open,
  onClose,
  selectedTransaction,
  borrowerId,
  fromLoans,
}) => {
  const intl = useIntl();

  const sublabel = intl.formatMessage({
    id: 'components.manualRetryPayment.paymentMethod',
  });
  const classes = useStyles();
  const dialogStyle = {
    style: {
      borderRadius: '16px',
    },
  };
  const retryOptions = [
    { label: 'Immédiatement', value: 'immediate' },
    // { label: 'Plus tard', value: 'schedule' }, TODO: add when schedule retry is available
  ];
  const [isSelectCardDialog, setIsSelectCardDialog] = useState(false);
  const [repaymentResultDialog, setRepaymentResultDialog] = useState(false);
  const [changePaymentCard, setChangePaymentCard] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [transactionAmount, setTransactionAmount] = useState(null);
  const [attemptDescription, setAttemptDescription] = useState();
  const [isRetryUnpaidInstallmentPaymentError, setIsRetryUnpaidInstallmentPaymentError] = useState(false);
  const [retryUnpaidInstallmentDialogTitle, setRetryUnpaidInstallmentDialogTitle] = useState();
  const [retryUnpaidInstallmentDialogDescription, setRetryUnpaidInstallmentDialogDescription] = useState();
  const [cardOptions, setCardOptions] = useState([]);
  const [manualRetryType, setManualRetryType] = useState('immediate');
  const [dialogContentLabel, setDialogContentLabel] = useState(
    intl.formatMessage({
      id: 'components.manualRetryPayment.initiateDebit',
    }),
  );
  const [continueButtonText, setContinueButtonText] = useState(
    intl.formatMessage({
      id: 'components.manualRetryPayment.button.attemptToDebit',
    }),
  );
  const handleChangeRetryType = event => {
    setManualRetryType(event.target.value);
  };

  useQuery(GET_PAYMENT_METHODS_BY_USER_ID, {
    variables: {
      userId: borrowerId,
    },
    onCompleted(response) {
      if (response?.getPaymentMethodsByUserId.length > 0) {
        setPaymentMethods(response.getPaymentMethodsByUserId);
        setSelectedPaymentMethod(response.getPaymentMethodsByUserId.find(card => card.default));
        setCardOptions(
          response.getPaymentMethodsByUserId.map(card => ({
            label: `•••• ${card.last4} (${card.issuer ?? ''})`,
            value: card.id,
          })),
        );
      } else {
        onClose();
        toast.error(intl.formatMessage({ id: 'components.manualRetryPayment.noPaymentMethodAvailable' }), {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setAttemptDescription(
      intl.formatMessage(
        {
          id: 'components.manualRetryPayment.attemptToDebit',
        },
        { last4: selectedPaymentMethod?.last4 },
      ),
    );
  }, [intl, selectedPaymentMethod, setSelectedPaymentMethod]);

  const displayErrorDialog = code => {
    const translatedCode = intl.formatMessage({
      id: `components.stripe.error.${code.toLowerCase()}.code`,
      defaultMessage: intl.formatMessage({
        id: `components.stripe.error.technical_error.code`,
      }),
    });
    const translatedMessage = intl.formatMessage({
      id: `components.stripe.error.${code.toLowerCase()}.message`,
      defaultMessage: intl.formatMessage({
        id: `components.stripe.error.technical_error.message`,
      }),
    });

    const errorMessage = `${translatedCode}<br>${translatedMessage}`;

    setRetryUnpaidInstallmentDialogTitle(
      intl.formatMessage({
        id: 'components.manualRetryPayment.error.title',
      }),
    );
    setRetryUnpaidInstallmentDialogDescription(
      intl.formatMessage(
        {
          id: 'components.manualRetryPayment.error.description',
        },
        { last4: selectedPaymentMethod?.last4, errorMessage: errorMessage },
      ),
    );
    setIsRetryUnpaidInstallmentPaymentError(true);
  };

  const [retryUnpaidInstallmentPayment, { loading: retryPaymentLoading }] = useMutation(
    RETRY_UNPAID_INSTALLMENT_PAYMENT,
    {
      onCompleted(res) {
        if (res) {
          const result = res.retryUnpaidInstallmentPaymentSafe;
          if (result.success === false) {
            const code = result.value.externalReasonCode;
            displayErrorDialog(code);
          } else {
            setIsRetryUnpaidInstallmentPaymentError(false);
            setRetryUnpaidInstallmentDialogTitle(
              intl.formatMessage({
                id: 'components.manualRetryPayment.success.title',
              }),
            );
            setRetryUnpaidInstallmentDialogDescription(
              intl.formatMessage(
                {
                  id: 'components.manualRetryPayment.success.description',
                },
                { last4: selectedPaymentMethod?.last4, amount: transactionAmount },
              ),
            );
          }
        }
      },
      onError(err) {
        console.error('RetryUnpaidInstallmentDialog an unexpected error occurred', err);
        displayErrorDialog('technical_error');
      },
    },
  );

  useEffect(() => {
    if (isSelectCardDialog) {
      setDialogContentLabel(
        intl.formatMessage({
          id: 'components.manualRetryPayment.verifyInitiateDebit',
        }),
      );
    }
    if (changePaymentCard) {
      setContinueButtonText(
        intl.formatMessage({
          id: 'components.manualRetryPayment.button.useCard',
        }),
      );
      setDialogContentLabel(
        intl.formatMessage({
          id: 'components.manualRetryPayment.changePaymentMethod',
        }),
      );
    } else {
      setContinueButtonText(
        intl.formatMessage({
          id: 'components.manualRetryPayment.button.attemptToDebit',
        }),
      );
    }
  }, [
    isSelectCardDialog,
    setIsSelectCardDialog,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    setDialogContentLabel,
    changePaymentCard,
    intl,
  ]);

  const handleClose = () => {
    if (!fromLoans) setPaymentMethods([]);
    setRepaymentResultDialog(false);
    onClose();
  };

  const handleContinue = async () => {
    if (changePaymentCard) {
      setChangePaymentCard(e => !e);
      setIsSelectCardDialog(true);
      return;
    }
    if (isSelectCardDialog && selectedPaymentMethod) {
      setTransactionAmount(selectedTransaction.principalAmount);
      await retryUnpaidInstallmentPayment({
        variables: {
          installmentId: selectedTransaction.id,
          paymentMethodId: selectedPaymentMethod.id,
        },
      });
      setDefaultValues();
      setRepaymentResultDialog(true);
      return;
    } else {
      setIsSelectCardDialog(e => !e);
    }
  };

  const setDefaultValues = () => {
    setDialogContentLabel(
      intl.formatMessage({
        id: 'components.manualRetryPayment.initiateDebit',
      }),
    );
    setIsSelectCardDialog(false);
    setChangePaymentCard(false);
  };

  const handleChangePaymentCard = event => {
    const newCard = paymentMethods.find(card => card.id === event.target.value);
    setSelectedPaymentMethod(newCard);
  };

  if (retryPaymentLoading) {
    return (
      <Dialog maxWidth={'md'} PaperProps={dialogStyle} open={true}>
        <DialogContent>
          <CircularProgress className={classes.loading} />
        </DialogContent>
      </Dialog>
    );
  }

  if (!repaymentResultDialog) {
    return (
      <Dialog maxWidth={'md'} PaperProps={dialogStyle} open={open} onClose={handleClose}>
        <DialogContent>
          {!isSelectCardDialog && !changePaymentCard && (
            <RadioButtonGroup
              label={dialogContentLabel}
              options={retryOptions}
              selectedValue={manualRetryType}
              onChange={handleChangeRetryType}
              align="row"
            />
          )}
          {isSelectCardDialog && paymentMethods && !changePaymentCard && (
            <ChevronCard
              label={dialogContentLabel}
              description={attemptDescription}
              boldDescription={selectedPaymentMethod?.last4}
              sublabel={sublabel}
              text={`•••• ${selectedPaymentMethod?.last4} (${selectedPaymentMethod?.issuer ?? ''})`}
              onClick={() => setChangePaymentCard(true)}
            />
          )}
          {changePaymentCard && (
            <RadioButtonGroup
              label={dialogContentLabel}
              sublabel={sublabel}
              options={cardOptions}
              selectedValue={selectedPaymentMethod.id}
              onChange={handleChangePaymentCard}
              align="column"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            className={classes.actionButton}
          >
            <Grid item>
              <button className={classes.cancelButton} onClick={handleClose}>
                {intl.formatMessage({
                  id: 'components.manualRetryPayment.cancel',
                })}
              </button>
            </Grid>
            <Grid item>
              <button className={classes.continueButton} onClick={handleContinue}>
                {continueButtonText}
              </button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
  return (
    <RetryUnpaidInstallmentResultDialog
      title={retryUnpaidInstallmentDialogTitle}
      description={retryUnpaidInstallmentDialogDescription}
      boldText={selectedPaymentMethod?.last4}
      open={repaymentResultDialog}
      onClose={handleClose}
      error={isRetryUnpaidInstallmentPaymentError}
    />
  );
};
