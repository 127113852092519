/* eslint no-mixed-operators:0 */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import notchedOutline from '@material-ui/core/OutlinedInput';

import { DatePicker } from '@material-ui/pickers';

import { Typography, FormControl, TextField, Grid } from '@material-ui/core';

import MomentUtils from '@date-io/moment/build';

import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import UPDATE_USER from '../../../../components/graphQL/mutations/user/update.gql';

import { FormattedMessage, injectIntl } from 'react-intl';

import StandardButton from '../../../../components/common/StandardButton';
import CommonStyles from '../../../../styles/common';

const styles = theme => ({
  ...CommonStyles(theme),
  accountTitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    fontWeight: '400',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  accountSubtitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    color: theme.palette.disabled,
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  notchedOutline: {},
  firstNameInput: {
    flexGrow: 1,
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  lastNameInput: {
    flexGrow: 1,
    marginRight: '0.5em',
    marginLeft: '0.5em',
    marginTop: '1em',
  },
  emailInput: {
    flexGrow: 1,
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  iconColorActive: {
    color: theme.palette.lightBlue,
  },
  iconColorInactive: {
    color: theme.palette.inactive,
  },
  phoneInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  birthdate: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  birthdateFullWidth: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
    width: '100%',
  },
  forceLowercase: {
    textTransform: 'lowercase',
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'contents',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'inherit',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
  dateLeft: {
    '&$notchedOutline': {
      borderColor: 'purple',
      borderRight: 'none',
      borderRadius: '8px 0 0 8px',
    },
  },
});

const AccountDetails = ({ classes, user, setUser, windowSize, intl }) => {
  const moment = new MomentUtils();
  const toastOption = {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
  };

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted() {
      toast.success(intl.formatMessage({ id: 'components.myaccount.savedDialogTitle' }), toastOption);
    },
    onError(e) {
      toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + e, toastOption);
    },
  });

  const handleInputChange = e => {
    if (!e || typeof e === 'undefined') {
      return;
    }

    const { name, value } = e.target;

    if (e.target.type === 'checkbox') {
      setUser({ ...user, [name]: e.target.checked });
    } else if (e.target.type === 'ToggleButtonGroup') {
      console.log('e.target.type', e.target.type);
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handleDateChange = val => {
    setUser({ ...user, birthdate: val });
  };

  const handleSaveAccount = () => {
    updateUser({
      variables: {
        id: user.id,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        address: user.address,
        birthdate:
          user.birthdate != null
            ? typeof user.birthdate === 'object'
              ? moment.format(user.birthdate, 'MM-DD-YYYY')
              : user.birthdate
            : user.birthdate,
        phone: user.phone,
      },
    });
  };

  return (
    <Paper elevation={0} className={classes.cardContainer}>
      <Typography variant="h1" className={classes.accountTitle}>
        <FormattedMessage id="components.session.myAccount" />
      </Typography>
      <Typography className={classes.accountSubtitle}>
        <FormattedMessage id="components.session.stepOne" />
      </Typography>
      <FormControl margin="normal" required fullWidth autoComplete="off">
        <Grid container justify="space-between">
          <TextField
            name="firstname"
            disabled
            className={classes.firstNameInput}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
              },
            }}
            label={<FormattedMessage id="components.session.firstName" />}
            variant="outlined"
            id="custom-css-outlined-input"
            value={(user && user.firstname) || ''}
            onChange={handleInputChange}
          />
          <TextField
            name="lastname"
            disabled
            className={classes.lastNameInput}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
              },
            }}
            label={<FormattedMessage id="components.session.lastName" />}
            variant="outlined"
            id="custom-css-outlined-input"
            value={(user && user.lastname) || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <TextField
          name="phone"
          disabled={user && user.userRole !== 'accountOwner'}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
            },
          }}
          className={classes.phoneInput}
          label={<FormattedMessage id="components.myaccount.mobilePhone" />}
          variant="outlined"
          id="custom-css-outlined-input"
          value={(user && user.phone) || ''}
          onChange={handleInputChange}
        />
        <TextField
          name="address"
          disabled={user && user.userRole !== 'accountOwner'}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,

              input: classes.forceLowercase,
            },
          }}
          className={classes.phoneInput}
          label={<FormattedMessage id="components.session.address" />}
          variant="outlined"
          id="address"
          value={(user && user.address) || ''}
          onChange={handleInputChange}
        />
        <Grid container justify="space-between">
          <DatePicker
            disabled={user && user.userRole !== 'accountOwner'}
            className={windowSize.innerWidth < 824 ? classes.birthdateFullWidth : classes.birthdate}
            variant="outlined"
            name="birthdate"
            label={<FormattedMessage id="components.session.birthdate" />}
            onChange={value => handleDateChange(value)}
            max={new Date()}
            value={user && user.birthdate ? new Date(user.birthdate) : null}
            format="DD/MM/YYYY"
            InputProps={{
              classes: {
                root: classes.dateLeft,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <TextField
            name="email"
            disabled={user && user.userRole !== 'accountOwner'}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,

                input: classes.forceLowercase,
              },
            }}
            className={classes.emailInput}
            label={<FormattedMessage id="components.session.workEmail" />}
            variant="outlined"
            id="workEmail"
            value={(user && user.email) || ''}
            onChange={handleInputChange}
          />
        </Grid>

        <div>
          {user && user.userRole === 'accountOwner' ? (
            <StandardButton
              onClick={() => handleSaveAccount()}
              customComponentStyling={{
                maxWidth: '50%',
                minWidth: '18%',
                marginRight: '0.5em',
                alignSelf: 'center',
                marginTop: '1em',
                marginBottom: '0',
                float: 'right',
              }}
            >
              <Typography variant="button">
                <FormattedMessage id="components.session.save" />
              </Typography>
            </StandardButton>
          ) : null}
        </div>
      </FormControl>
    </Paper>
  );
};
export default injectIntl(withStyles(styles)(AccountDetails));

AccountDetails.defaultProps = {
  user: null,
};

AccountDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  windowSize: PropTypes.object.isRequired,
  intl: PropTypes.object,
};
