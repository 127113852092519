import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';

import check from '../../../../images/check.png';
import redCross from '../../../../images/redCross.png';
import { Calendar } from '../../../Dashboard/User/components/PromesseModal/Calendar';
import { fromDate } from '@internationalized/date';
import CHANGE_INSTALLMENT_PAYMENT_DAY from './changeInstallmentPaymentDay.gql';

export const ChangeDueDateWizard = ({
  open,
  handleClose,
  dueAt,
  creditId,
  installmentId,
  initialDueAt,
  previousDueAt,
  number,
}) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState();
  const [done, setDone] = useState();
  const [step, setStep] = useState(1);

  const [changeInstallmentPaymentDay] = useMutation(CHANGE_INSTALLMENT_PAYMENT_DAY, {
    onCompleted() {
      setDone(true);
    },
    onError() {
      setDone(false);
    },
  });

  const sendMutation = () => {
    changeInstallmentPaymentDay({
      variables: {
        creditId: creditId,
        installmentId: installmentId,
        newDate: moment(selectedDate?.toDate()).format('YYYY-MM-DD'),
      },
    });
    setStep(prev => prev + 1);
  };

  const handleNext = () => {
    setStep(prev => prev + 1);
  };
  const handleValidate = () => {
    sendMutation();
  };

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  let newInitialDueAt = fromDate(new Date(initialDueAt), 'cet');
  if (new Date(initialDueAt) !== new Date()) newInitialDueAt = fromDate(addDays(new Date(), 1));
  if (previousDueAt && new Date(previousDueAt) > new Date())
    newInitialDueAt = fromDate(new Date(previousDueAt));
  let limitDate = fromDate(addDays(initialDueAt, 15));
  if (number === 3) limitDate = fromDate(new Date(initialDueAt), 'cet');

  return (
    <Dialog maxWidth={'md'} open={open} onClose={() => handleClose(true)}>
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogTitle className={classes.dialogTitle}>Changement de date de prélèvement</DialogTitle>
      {step === 1 && (
        <DialogContent>
          <Calendar
            minValue={newInitialDueAt}
            maxValue={limitDate}
            focusedValue={selectedDate}
            onFocusChange={setSelectedDate}
            weekdayStyle="long"
          ></Calendar>
        </DialogContent>
      )}
      {step === 2 && (
        <DialogContent>
          <DialogContentText>
            Ancienne date de prélèvement : {moment(dueAt).locale('fr').format('LL')}
          </DialogContentText>
          <DialogContentText>
            Souhaitez-vous confirmer un changement de date de prélèvement au{' '}
            {moment(selectedDate?.toDate()).locale('fr').format('LL')} ?
          </DialogContentText>
        </DialogContent>
      )}
      {step === 3 && done && (
        <DialogContentText className={classes.blocDone}>
          <img src={check} className={classes.done} alt="done logo" />
          Changement de date de prélèvement confirmé.
        </DialogContentText>
      )}
      {step === 3 && done === false && (
        <DialogContentText className={classes.blocDone}>
          <img src={redCross} className={classes.done} alt="not done logo" />
          Le changement de date de prélèvement a échoué.
        </DialogContentText>
      )}
      <DialogActions>
        {(step === 1 || step === 2) && (
          <Button className={classes.cancelButton} onClick={() => handleClose(true)}>
            Annuler
          </Button>
        )}
        {step === 1 && (
          <Button
            classes={{ disabled: classes.disabledButton }}
            disabled={!selectedDate}
            className={classes.nextButton}
            onClick={() => handleNext()}
          >
            Suivant
          </Button>
        )}
        {step === 2 && (
          <Button className={classes.nextButton} onClick={() => handleValidate()}>
            Valider
          </Button>
        )}
        {step === 3 && (
          <Button
            className={classes.nextButton}
            onClick={() => {
              handleClose(true);
            }}
          >
            Fermer
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    paddingRight: '60px',
  },
  nextButton: {
    display: 'flex',
    padding: '9px 45.5px 9px 44.5px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: '#081433',
    color: '#FFFFFF',
    textAlign: 'center',
    fonFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
  },
  cancelButton: {
    backgroundColor: theme.palette.grey[200],
    display: 'flex',
    padding: '9px 45.5px 9px 44.5px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    color: '#081433',
    textAlign: 'center',
    fonFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
  },
  disabledButton: {
    backgroundColor: theme.palette.grey[500],
  },
  done: {
    width: '120px',
    Height: '120px',
  },
  blocDone: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[700],
  },
  dialog: {
    borderRadius: '16px',
  },
}));
