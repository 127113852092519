import React from 'react';
import { Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import combineStyles from '../../styles/combineStyles';
// Secondary styling of the component (import)
// can be imported from external file
import signupButton from '../../styles/commonStyling';

// Primary styling of the component
// is injected through Material UI's withStyles HOC
const styles = theme => ({
  standardButtonStyle: {
    background: '#081433',
    borderRadius: theme.custom.borderRadius,
    border: 0,
    // big button styling
    height: 50,
    minWidth: 200,
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      // small button styling
      height: 46,
      paddingRight: '1em',
      paddingLeft: '1em',
      minWidth: 125,
    },
    '&:hover': {
      opacity: theme.custom.standardOpacity,
    },
    '&:disabled': {
      background: theme.palette.disabled,
    },
  },
});

const StandardButton = props => {
  const {
    children,
    customComponentStyling,
    disabled,
    classes: { standardButtonStyle, customClasses },
    ...rest
  } = props;

  if (customComponentStyling === true) {
    return (
      <Button className={`${standardButtonStyle} ${customClasses}`} disabled={disabled} {...rest}>
        {children}
      </Button>
    );
  }
  return (
    <Button
      // Third styling of the component (highest specificity, will override any other style).
      // Comes from the parent component as props. If no style is passed from parent, the
      // component will only apply primary and secondary stylings
      disabled={disabled}
      style={customComponentStyling}
      className={`${standardButtonStyle} ${customClasses}`}
      {...rest}
    >
      {children}
    </Button>
  );
};

StandardButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  customComponentStyling: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
};

StandardButton.defaultProps = {
  disabled: false,
};

// Mix here secondary styling with primary styling
// with the helper method combineStyles. If no secondary
// styling is used, remove it from the combine function below.
const combinedStyles = combineStyles(styles, signupButton);

export default withStyles(combinedStyles)(StandardButton);
