import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { Player } from '@lottiefiles/react-lottie-player';
import { isMobile } from 'react-device-detect';
import QueryString from 'query-string';
import {
  Background,
  BoldText,
  Button,
  ButtonWrapper,
  CloseIcon,
  Content,
  ContentWrapper,
  EmailIcon,
  EmailIconWrapper,
  HamburgerMenuIcon,
  Logo,
  NavBar,
  NavItem,
  NavItemContainer,
  SocialIcon,
  SocialIconsWrapper,
  SocialMobileDropdown,
  Subtitle,
  Title,
} from './components';

import DjangoLogo from '../images/logo-django.png';
import SocialIconFB from '../images/social-icon-fb.png';
import SocialIconInstagram from '../images/social-icon-instagram.png';
import SocialIconLinkedIn from '../images/social-icon-linkedIn.png';
import HamburgerIcon from '../images/hamburger-icon.svg';
import CloseCircleIcon from '../images/close-circle.svg';
import EmailIconAnimated from '../images/emailSentAnimation.json';
import VERIFY_EMAIL from './verifyEmail.gql';

const DEEP_LINK_REDIRECTION = 'joeapp://email/verified';

const VerifyEmail = ({ location: { search } }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [openSocialDropdown, setOpenSocialDropdown] = useState(false);
  const qs = QueryString.parse(search);

  const [verifyEmail] = useMutation(VERIFY_EMAIL, {
    variables: {
      token: qs.token,
    },
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setError(true);
      setLoading(false);
    },
    onLoading() {
      setLoading(true);
    },
  });

  useEffect(() => {
    if (qs.token) {
      verifyEmail();
    } else {
      setError(true);
      setLoading(false);
    }
  }, []);

  const openMenuDropdown = () => {
    setOpenSocialDropdown(!openSocialDropdown);
  };

  const openApplication = () => {
    window.location.href = `${DEEP_LINK_REDIRECTION}`;
  };

  const setBoldText = chunks => <BoldText>{chunks}</BoldText>;
  return (
    <Background>
      <NavBar>
        <NavItemContainer>
          <NavItem>
            <Logo src={DjangoLogo} />
          </NavItem>
          <NavItem>
            <SocialIconsWrapper>
              <a
                href="https://www.facebook.com/people/Django/100083331902811/"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon src={SocialIconFB} />
              </a>
              <a
                href="https://instagram.com/django_france?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon src={SocialIconInstagram} />
              </a>
              <a href="https://www.linkedin.com/company/djangofrance/" target="_blank" rel="noreferrer">
                <SocialIcon src={SocialIconLinkedIn} />
              </a>
            </SocialIconsWrapper>
            <HamburgerMenuIcon src={HamburgerIcon} onClick={openMenuDropdown} />
          </NavItem>
        </NavItemContainer>
      </NavBar>
      {openSocialDropdown && (
        <SocialMobileDropdown>
          <a href="https://www.facebook.com/people/Django/100083331902811/" target="_blank" rel="noreferrer">
            <SocialIcon src={SocialIconFB} />
          </a>
          <a href="https://instagram.com/django_france?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
            <SocialIcon src={SocialIconInstagram} />
          </a>
          <a href="https://www.linkedin.com/company/djangofrance/" target="_blank" rel="noreferrer">
            <SocialIcon src={SocialIconLinkedIn} />
          </a>
        </SocialMobileDropdown>
      )}
      <ContentWrapper>
        {!loading && (
          <Content>
            {!error ? (
              <>
                <EmailIconWrapper>
                  <EmailIcon>
                    <Player src={EmailIconAnimated} className="player" loop autoplay />
                  </EmailIcon>
                </EmailIconWrapper>
                <Title>
                  <FormattedMessage id="components.verifiedEmail.success.title" />
                </Title>
                <Subtitle>
                  <FormattedMessage id="components.verifiedEmail.success.subtitle1" />
                </Subtitle>
                <Subtitle style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="components.verifiedEmail.success.subtitle2"
                    values={{
                      b: text => setBoldText(text),
                    }}
                  />
                </Subtitle>
                <Subtitle style={{ marginBottom: 20 }}>
                  <FormattedMessage
                    id="components.verifiedEmail.success.subtitle3"
                    values={{
                      b: text => setBoldText(text),
                    }}
                  />
                </Subtitle>
                <Subtitle>
                  <FormattedMessage id="components.verifiedEmail.success.subtitle4" />
                </Subtitle>
              </>
            ) : (
              <>
                <CloseIcon src={CloseCircleIcon} />
                <Title>
                  <FormattedMessage id="components.verifiedEmail.error.title" />
                </Title>
                <Subtitle style={{ marginBottom: 20 }}>
                  <FormattedMessage id="components.verifiedEmail.error.subtitle1" />
                </Subtitle>
                <Subtitle>
                  <FormattedMessage id="components.verifiedEmail.error.subtitle2" />
                </Subtitle>
              </>
            )}
            {isMobile && (
              <ButtonWrapper>
                <Button onClick={openApplication}>
                  <FormattedMessage id="components.verifiedEmail.buttonTitle" />
                </Button>
              </ButtonWrapper>
            )}
          </Content>
        )}
      </ContentWrapper>
    </Background>
  );
};

export default VerifyEmail;
