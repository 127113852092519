import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import djangoLogo from '../../images/djangoLogo.png';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  adjustLoader: {
    marginTop: '2em',
    margin: '0 auto',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  adjustLogo: {
    marginTop: '35vh',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '40vh',
      width: '80%',
    },
  },
});

const Loading = props => {
  const {
    classes: { adjustLoader, adjustLogo, root },
  } = props;
  return (
    <div>
      <Grid container direction="column" alignItems="center" justifyContent="center" className={root}>
        <Grid item xs={12}>
          <img src={djangoLogo} alt="Joe Logo" className={adjustLogo} />
          <Grid item xs={12} className={adjustLoader}>
            <LinearProgress color="primary" />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Loading);

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};
