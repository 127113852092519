import styled, { keyframes } from 'styled-components';

export const CenterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify: center;
  flex-direction: column;
  @media (max-width: 675px) {
    padding-right: 0.5em;
  }
`;

export const CircleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 675px) {
    padding-top: 5em;
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 10em;
  @media (max-width: 675px) {
    padding-top: 5em;
  }
`;

export const BigText = styled.h1`
  display: flex;
  width: 100%;
  height: 100%;
  color: black;
  font-size: 50px;
  @media (max-width: 675px) {
    font-size: 30px;
  }
  padding-right: 1em;
  padding-left: 1em;
  padding-bottom: 2em;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Loading = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  align-items: center;
  border-top: 2px solid #00f19d;
  border-right: 2px solid #00f19d;
  border-bottom: 2px solid #00f19d;
  border-left: 4px solid #19cff1;
  background: transparent;
  width: ${props => (props.width ? `${props.width}px` : '150px')};
  height: ${props => (props.height ? `${props.height}px` : '150px')};
  border-radius: 50%;
`;

export const Background = styled.div`
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: #e3e7f0;
  @media (max-width: 675px) {
    background-color: white;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  @media (max-height: 668px) {
    margin-top: 0px;
  }
  @media (max-height: 741px) {
    margin-top: 0px;
  }
`;

export const Content = styled.div`
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 620px;
  border-radius: 16px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 30px;
  padding-bottom: 40px;
  @media (max-width: 675px) {
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 16px;
    background-color: transparent;
  }
`;

export const NavBar = styled.div`
  width: 100%;
  background-color: #21283a;
  padding-left: 5%;
  padding-right: 5%;
`;

export const NavItemContainer = styled.div`
  max-width: 940px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
`;

export const NavItem = styled.div`
  width: 50%;
  height: 70px;
  float: left;
  display: inline;
  @media (max-width: 675px) {
    margin-top: 0px;
  }
`;

export const Title = styled.div`
  min-width: 100%;
  color: #040620;
  font-size: 28px;
  line-height: 48px;
  font-family: Monserrat-Bold, sans-serif;
  text-align: left;
  margin-bottom: 30px;
  @media (max-width: 675px) {
    font-size: 22px;
    line-height: 30px;
  }
`;

export const Subtitle = styled.div`
  font-family: Montserrat-Regular, sans-serif;
  color: rgba(4, 6, 32, 0.7);
  text-align: left;
}
`;

export const BoldText = ({ children }) => {
  return <span style={{ fontWeight: 'bold' }}>{children}</span>;
};

export const ButtonWrapper = styled.div`
  margin-top: 50px;
}
`;

export const Button = styled.button`
  border-radius: 8px;
  background-color: #ff6b4f;
  font-size: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border: 0;
  color: white;
`;

export const Logo = styled.img`
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  float: left;
`;

export const SocialIconsWrapper = styled.div`
  width: auto;
  height: 30px;
  float: right;
  @media (max-width: 675px) {
    display: none;
  }
`;

export const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-top: 22px;
  margin-bottom: 10px;
  margin-left: 10px;
  @media (max-width: 675px) {
    margin-left: 16px;
  }
`;

export const EmailIcon = styled.div`
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
`;

export const EmailIconWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}
`;

export const HamburgerMenuIcon = styled.img`
  width: 24px;
  height: 24px;
  float: right;
  margin-right: 16px;
  margin-top: 24px;
  display: none;
  @media (max-width: 675px) {
    display: block;
  }
}
`;

export const CloseIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  @media (max-width: 675px) {
    width: 40px;
    height: 40px;
  }
}
`;

export const SocialMobileDropdown = styled.div`
  width: 100%;
  background-color: #21283a;
  padding-bottom: 10px;
  position: absolute;
  top: 75px;
}
`;
