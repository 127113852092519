import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [lastname, setLastname] = useState(null);
  const [verified, setVerified] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [linkedBankAccount, setLinkedBankAccount] = useState(null);
  const [identityVerified, setIdentityVerified] = useState(null);
  const [kycInError, setKycInError] = useState(null);
  const [emailVerified, setEmailVerified] = useState(null);
  const [minLoanCapacity, setMinLoanCapacity] = useState(null);
  const [maxLoanCapacity, setMaxLoanCapacity] = useState(null);
  const [kycWithoutResponse, setKycWithoutResponse] = useState(null);
  const [activeUser, setActiveUser] = useState(null);
  const [phone, setPhone] = useState(null);
  const [filter, setFilter] = useState(false);
  const [isFraud, setIsFraud] = useState(null);
  const [userName, setUserName] = useState(null);
  const [state, setState] = useState(null);
  const [isAmlCftAlert, setIsAmlCftAlert] = useState(null);

  return (
    <FilterContext.Provider
      value={{
        lastname,
        setLastname,
        verified,
        setVerified,
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        linkedBankAccount,
        setLinkedBankAccount,
        identityVerified,
        setIdentityVerified,
        kycInError,
        setKycInError,
        emailVerified,
        setEmailVerified,
        minLoanCapacity,
        setMinLoanCapacity,
        maxLoanCapacity,
        setMaxLoanCapacity,
        kycWithoutResponse,
        setKycWithoutResponse,
        activeUser,
        setActiveUser,
        phone,
        setPhone,
        filter,
        setFilter,
        isFraud,
        setIsFraud,
        userName,
        setUserName,
        state,
        setState,
        isAmlCftAlert,
        setIsAmlCftAlert,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
