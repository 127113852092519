import React from 'react';
import { ListItem, ListItemText, ListItemIcon, makeStyles, Grid } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FormLabel } from 'react-bootstrap';
import { DynamicBoldText } from './DynamicBoldText';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '760px',
    padding: '16px',
  },
  chevronContainer: { display: 'flex', justifyContent: 'space-between', paddingBottom: '16px' },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#040620',
    borderColor: '#E6E6E9',
    borderStyle: 'solid',
    padding: '8px 16px',
    cursor: 'pointer',
  },
  chevronIcon: {
    fontSize: '2rem',
    color: '#FF6B4F',
  },
  label: {
    fontFamily: 'Lato',
    fontSize: '32px',
    fontWeight: 800,
    color: '#081433',
    lineHeight: '38.4px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
  description: {
    fontFamily: 'Lato',
    fontSize: '23px',
    fontWeight: 500,
    color: '#081433',
    lineHeight: '27.6px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
  sublabel: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 600,
    color: '#081433',
    lineHeight: '22.5px',
    textAlign: 'left',
    marginBottom: '16px',
    paddingTop: '14px',
  },
}));

const ChevronCard = ({ label, description, boldDescription, sublabel, text, onClick, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      {label && (
        <FormLabel component="legend" className={classes.label} {...props}>
          {label}
        </FormLabel>
      )}
      {description && (
        <FormLabel component="legend" className={classes.description} {...props}>
          <DynamicBoldText text={description} boldText={boldDescription} />
        </FormLabel>
      )}
      {sublabel && (
        <FormLabel component="legend" className={classes.sublabel} {...props}>
          {sublabel}
        </FormLabel>
      )}
      <Grid container direction="row" spacing={2} className={classes.chevronContainer}>
        <ListItem className={classes.listItem} onClick={onClick} {...props}>
          <ListItemText primary={text} />
          <ListItemIcon>
            <ChevronRightIcon className={classes.chevronIcon} />
          </ListItemIcon>
        </ListItem>
      </Grid>
    </Grid>
  );
};

export default ChevronCard;
