import React, { useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import SET_COOKIE from '../../../components/graphQL/mutations/auth/setCookie.gql';
import BI_REQUEST_TEMPORARY_CODE from './biRequestTemporaryCode.gql';
import RECONNECT_ACCOUNTS from './reconnectAccounts.gql';
import QueryString from 'query-string';
import { LoadingWrapper, Loading } from '../components';
import { environment } from '../../../environments/environment';

const budgetClientId = environment.BUDGET_CLIENTID;
const budgetHost = environment.BUDGET_API_HOST; // TECHNICAL DEBT clean moygo
const budgetRedirectUri = environment.BUDGET_RECONNEXION_REDIRECT_URI;
const redirectUri = environment.BUDGET_REDIRECT_URI;

const API_HOST = `https://webview.powens.com`;

const BankAccountReconnexion = ({ location: { search } }) => {
  const qs = QueryString.parse(search);
  const [biRequestTemporaryCode] = useLazyQuery(BI_REQUEST_TEMPORARY_CODE, {
    onCompleted(response) {
      window.location.href = `${API_HOST}/reconnect?domain=${budgetHost}&client_id=${budgetClientId}&code=${response.biRequestTemporaryCode}&connection_id=${qs.biConnectionId}&redirect_uri=${budgetRedirectUri}`;
    },
  });
  const [setCookie] = useMutation(SET_COOKIE, {
    onCompleted() {
      biRequestTemporaryCode({
        variables: {
          biConnectionId: qs.biConnectionId,
        },
      });
    },
  });

  const [reconnectAccounts] = useMutation(RECONNECT_ACCOUNTS, {
    onCompleted() {
      window.location.href = redirectUri;
    },
  });

  useEffect(() => {
    setCookie({
      variables: {
        token: qs.token,
      },
    });
  }, [qs.token, setCookie]);

  useEffect(() => {
    if (qs?.connection_id || qs?.error) {
      const { error } = qs;
      if (error) {
        window.location.href = redirectUri;
      } else {
        reconnectAccounts({
          variables: {
            biConnectionId: qs.connection_id,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qs?.connection_id, qs?.error]);

  return (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default BankAccountReconnexion;
