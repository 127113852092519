import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { useDebounce } from '../../../../hooks/useDebounce';
import notchedOutline from '@material-ui/core/OutlinedInput';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControlLabel,
  Switch,
  Grid,
  TextField,
  CircularProgress,
  Button,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { WarningOutlined as Warning } from '@material-ui/icons';
import FilterListIcon from '@material-ui/icons/FilterList';
import MomentUtils from '@date-io/moment';
import { useMutation, useLazyQuery } from '@apollo/client';
import { exportCsv } from '../../utils';

import { FormattedMessage, injectIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { Query } from '@apollo/client/react/components';

import { FilterContext } from '../../FilterContext';

import USERS from './users.gql';
import UPDATE_USER_ACTIVITY from './updateUserActivity.gql';

const DEFAULT_ROWS_PER_PAGE = 10;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const verifiedStates = [
  { label: 'all', value: null },
  { label: 'unverified', value: false },
  { label: 'verified', value: true },
];

const connectedStates = [
  { label: 'all', value: null },
  { label: 'unconnected', value: false },
  { label: 'connected', value: true },
];

const kycErrorStatus = [
  { label: 'all', value: false },
  { label: 'error', value: true },
];

const activeState = [
  { label: 'all', value: null },
  { label: 'inactive', value: false },
];

const kycNoResponse = [
  { label: 'all', value: null },
  { label: 'No response from ubble', value: true },
];

const fraudSuspicion = [
  { label: 'all', value: null },
  { label: 'Fraud Suspicion', value: true },
];

const amlCftAlert = [
  { label: 'all', value: null },
  { label: 'AML & CFT alerte', value: true },
];

const headCells = [
  { id: 'lastname', numeric: false, disablePadding: false, label: 'Nom' },
  { id: 'firstname', numeric: false, disablePadding: false, label: 'Prénom' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Téléphone' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Date inscription',
  },
  {
    id: 'birthdate',
    numeric: false,
    disablePadding: false,
    label: 'Date de naissance',
  },
  { id: 'active', numeric: false, disablePadding: false, label: 'Actif' },
  { id: 'verified', numeric: false, disablePadding: false, label: 'Vérifié' },
  {
    id: 'emailVerified',
    numeric: false,
    disablePadding: false,
    label: 'Email vérifié',
  },
  {
    id: 'phoneVerified',
    numeric: false,
    disablePadding: false,
    label: 'Téléphone vérifié',
  },
  {
    id: 'identityVerified',
    numeric: false,
    disablePadding: false,
    label: 'Identité vérifié',
  },
  {
    id: 'ageVerified',
    numeric: false,
    disablePadding: false,
    label: 'Age vérifié',
  },
  {
    id: 'identityNameAlert',
    numeric: false,
    disablePadding: false,
    label: "Alerte d'identité",
  },
  {
    id: 'ubbleVerificationStatus',
    numeric: false,
    disablePadding: false,
    label: 'Ubble Status',
  },
  {
    id: 'leftLoanCapacity',
    numeric: false,
    disablePadding: false,
    label: "Capacité restante d'emprunt",
  },
  {
    id: 'totalLoanCapacity',
    numeric: false,
    disablePadding: false,
    label: "Capacité totale d'emprunt",
  },
  {
    id: 'linkedBankAccount',
    numeric: false,
    disablePadding: false,
    label: 'Compte Bancaire',
  },
  // { id: 'baseOs', numeric: false, disablePadding: false, label: 'Appareil' },
  {
    id: 'ubbleVerificationAttempts',
    numeric: false,
    disablePadding: false,
    label: 'Tentatives de KYC',
  },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all users' }}
            name="checkbox"
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const {
    numSelected,
    filter,
    setFilter,
    selected,
    setSelected,
    refetch,
    phone,
    lastname,
    verified,
    fromDate,
    toDate,
    linkedBankAccount,
    emailVerified,
    identityVerified,
    minLoanCapacity,
    maxLoanCapacity,
    activeUser,
    opts,
    intl,
  } = props;
  const [updateUserActivity] = useMutation(UPDATE_USER_ACTIVITY, {
    onCompleted() {
      setSelected([]);
      refetch();
    },
  });

  const [users] = useLazyQuery(USERS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response?.users) exportCsv(response.users.users || [], 'users', opts, intl);
    },
  });

  const updateActivityMutation = active => {
    updateUserActivity({
      variables: {
        ids: selected,
        active,
      },
    });
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <>
          <Tooltip className={classes.margin} title="Activate">
            <Button variant="contained" color="primary" onClick={() => updateActivityMutation(true)}>
              Activer
            </Button>
          </Tooltip>
          {/* <Tooltip className={classes.margin} title="Disable">
            <Button variant="contained" color="secondary" onClick={() => sendMutation(false)}>
              Désactiver
            </Button>
          </Tooltip> */}
        </>
      ) : (
        <Tooltip title="Filtre">
          <IconButton aria-label="Filtre" onClick={() => setFilter(!filter)}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}

      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} sélectioné(s)
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="components.utils.users" />
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: 5 }}
        onClick={() => {
          users({
            variables: {
              phone: phone || null,
              lastname: lastname || null,
              verified: verified,
              fromDate: fromDate,
              toDate: toDate,
              linkedBankAccount: linkedBankAccount,
              identityVerified: identityVerified,
              emailVerified: emailVerified,
              minLoanCapacity: minLoanCapacity,
              maxLoanCapacity: maxLoanCapacity,
              active: activeUser,
            },
          });
        }}
      >
        EXPORT
      </Button>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  blackWarning: {
    position: 'relative',
    marginLeft: '5px',
    top: '2px',
    color: 'black',
    cursor: 'pointer',
    fontSize: 20,
  },
  redWarning: {
    position: 'relative',
    marginLeft: '5px',
    top: '2px',
    color: 'red',
    cursor: 'pointer',
    fontSize: 20,
  },
}));

const UsersContainer = ({ intl }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [users, setUsers] = useState([]);
  const moment = new MomentUtils();

  const {
    lastname,
    setLastname,
    verified,
    setVerified,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    linkedBankAccount,
    setLinkedBankAccount,
    identityVerified,
    setIdentityVerified,
    kycInError,
    setKycInError,
    emailVerified,
    setEmailVerified,
    minLoanCapacity,
    setMinLoanCapacity,
    maxLoanCapacity,
    setMaxLoanCapacity,
    kycWithoutResponse,
    setKycWithoutResponse,
    activeUser,
    setActiveUser,
    phone,
    setPhone,
    filter,
    setFilter,
    isFraud,
    setIsFraud,
    isAmlCftAlert,
    setIsAmlCftAlert,
  } = useContext(FilterContext);
  const debouncedLastName = useDebounce(lastname, 1000);
  const debouncedPhone = useDebounce(phone, 1000);
  const debouncedFromDate = useDebounce(fromDate, 1000);
  const debouncedToDate = useDebounce(toDate, 1000);
  const debouncedMinLoanCapacity = useDebounce(minLoanCapacity, 1000);
  const debouncedMaxLoanCapacity = useDebounce(maxLoanCapacity, 1000);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, users) => {
    if (event.target.checked) {
      const newSelecteds = users.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const fields = headCells.map(cell => cell.id);
  const opts = { fields };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {filter && (
          <Grid container flexDirection="row" justify="flex-start">
            <TextField
              name="lastname"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.session.lastName" />}
              variant="outlined"
              id="custom-css-outlined-input"
              value={lastname || ''}
              onChange={e => setLastname(e.target.value)}
            />
            <TextField
              name="phone"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.session.phone" />}
              variant="outlined"
              id="custom-css-outlined-input"
              value={phone || ''}
              onChange={e => setPhone(e.target.value)}
            />
            <TextField
              select
              name="verified"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.statut" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Selectionnez un statut"
              value={verified}
              onChange={e => setVerified(e.target.value)}
            >
              {verifiedStates.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              name="fromDate"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              variant="outlined"
              id="standard-select-state"
              helperText="Inscrit depuis le"
              type="date"
              onChange={e => setFromDate(e.target.value)}
            />
            <TextField
              name="toDate"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              variant="outlined"
              id="standard-select-state"
              helperText="Inscrit jusqu'au"
              type="date"
              onChange={e => setToDate(e.target.value)}
            />
            <TextField
              select
              name="linkedBankAccount"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.linkedBankAccount" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Selectionnez un statut bancaire"
              value={linkedBankAccount}
              onChange={e => setLinkedBankAccount(e.target.value)}
            >
              {connectedStates.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="identityVerified"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.identityVerified" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Selectionnez un statut d'identité"
              value={identityVerified}
              onChange={e => setIdentityVerified(e.target.value)}
            >
              {verifiedStates.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="kycInError"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.kycInError" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Erreur de KYC"
              value={kycInError}
              onChange={e => setKycInError(e.target.value)}
            >
              {kycErrorStatus.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="emailVerified"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.emailVerified" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Selectionnez un statut d'email"
              value={emailVerified}
              onChange={e => setEmailVerified(e.target.value)}
            >
              {verifiedStates.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="number"
              name="minLoanCapacity"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.minAmount" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Montant min attribué"
              value={minLoanCapacity}
              onChange={e => setMinLoanCapacity(e.target.value || null)}
            >
              {verifiedStates.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              type="number"
              name="maxLoanCapacity"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.maxAmount" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Montant max attribué"
              value={maxLoanCapacity}
              onChange={e => setMaxLoanCapacity(e.target.value || null)}
            >
              {verifiedStates.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="activeState"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.utils.activeUser" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Selectionnez un statut d'activité"
              value={activeUser}
              onChange={e => setActiveUser(e.target.value)}
            >
              {activeState.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="kycWithoutResponse"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.admin.kycWithoutResponse" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Filtrer sur Ubble fait sans réponse"
              value={kycWithoutResponse}
              onChange={e => setKycWithoutResponse(e.target.value)}
            >
              {kycNoResponse.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="fraudSuspicion"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.admin.fraudSuspicion" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Filtrer sur suspicion de fraude"
              value={isFraud}
              onChange={e => setIsFraud(e.target.value)}
            >
              {fraudSuspicion.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              name="amlCftAlert"
              className={classes.filterInput}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                },
              }}
              label={<FormattedMessage id="components.admin.amlCftAlert" />}
              variant="outlined"
              id="standard-select-state"
              helperText="Filtrer sur AML & CFT alertes"
              value={isAmlCftAlert}
              onChange={e => setIsAmlCftAlert(e.target.value)}
            >
              {amlCftAlert.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
        <Query
          query={USERS}
          fetchPolicy="network-only"
          variables={{
            phone: debouncedPhone || null,
            lastname: debouncedLastName || null,
            verified,
            fromDate: debouncedFromDate,
            toDate: debouncedToDate,
            linkedBankAccount,
            identityVerified,
            emailVerified,
            minLoanCapacity: debouncedMinLoanCapacity,
            maxLoanCapacity: debouncedMaxLoanCapacity,
            kycWithoutResponse,
            isFraud,
            isAmlCftAlert,
            active: activeUser,
            kycInError,
            page,
            perPage: rowsPerPage,
          }}
        >
          {({ data, error, loading, refetch }) => {
            if (loading) return <CircularProgress />;
            if (error) {
              if (error.networkError) {
                toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                });
              } else {
                toast.error(
                  intl.formatMessage({
                    id: 'components.utils.errorHasOccured',
                  }) + error.message,
                  {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  },
                );
              }
              return '';
            }

            if (data?.users?.users) setUsers(data.users.users);
            if (data?.users?.count) setCount(data.users.count);

            return (
              <>
                <EnhancedTableToolbar
                  intl={intl}
                  numSelected={selected.length}
                  filter={filter}
                  setFilter={setFilter}
                  selected={selected}
                  setSelected={setSelected}
                  refetch={refetch}
                  phone={phone}
                  lastname={lastname}
                  verified={verified}
                  fromDate={fromDate}
                  toDate={toDate}
                  linkedBankAccount={linkedBankAccount}
                  emailVerified={emailVerified}
                  identityVerified={identityVerified}
                  minLoanCapacity={minLoanCapacity}
                  maxLoanCapacity={maxLoanCapacity}
                  activeUser={activeUser}
                  opts={opts}
                />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={event => handleSelectAllClick(event, users)}
                      onRequestSort={handleRequestSort}
                      rowCount={users?.length}
                    />
                    <TableBody>
                      {stableSort(users, getComparator(order, orderBy))
                        .slice(0, rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  name="checkbox"
                                />
                              </TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <Link to={`/dashboard/users/${row.id}`}>{row.lastname}</Link>
                              </TableCell>
                              <TableCell align="left">
                                <Link to={`/dashboard/users/${row.id}`}>{row.firstname}</Link>
                              </TableCell>
                              <TableCell align="left">{row.phone}</TableCell>
                              <TableCell align="left">{row.email}</TableCell>
                              <TableCell align="left">
                                {row.createdAt && moment.format(moment.date(row.createdAt), 'fullDate')}
                              </TableCell>
                              <TableCell align="left">
                                {row.birthdate && moment.format(moment.date(row.birthdate), 'fullDate')}
                              </TableCell>
                              <TableCell align="left">{row.active?.toString()}</TableCell>
                              <TableCell align="left">
                                {!row.verified && <Warning className={classes.blackWarning} />}
                              </TableCell>
                              <TableCell align="left">{row.emailVerified?.toString()}</TableCell>
                              <TableCell align="left">{row.phoneVerified?.toString()}</TableCell>
                              <TableCell align="left">
                                {!row.identityVerified && <Warning className={classes.redWarning} />}
                              </TableCell>
                              <TableCell align="left">
                                {!row.ageVerified && row.identityVerified && (
                                  <Warning className={classes.redWarning} />
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {row.identityNameAlert && <Warning className={classes.redWarning} />}
                              </TableCell>
                              <TableCell align="left">
                                {row.ubbleVerificationStatus || 'uninitiated'}
                              </TableCell>
                              <TableCell align="left">{row.leftLoanCapacity}</TableCell>
                              <TableCell align="left">{row.totalLoanCapacity}</TableCell>
                              <TableCell align="left">
                                {!row.linkedBankAccount && (
                                  <Warning
                                    className={classes[row.needToUpdateIban ? 'redWarning' : 'blackWarning']}
                                  />
                                )}
                              </TableCell>
                              {/* <TableCell align="left">{row.baseOs}</TableCell> */}
                              <TableCell align="left">{row.ubbleVerificationAttempts}</TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            );
          }}
        </Query>
      </Paper>
      <FormControlLabel
        name="switch"
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Affichage recentré"
      />
    </div>
  );
};

export default injectIntl(UsersContainer);
