import React, { useCallback, useState } from 'react';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';

import { toast } from 'react-toastify';
import { FormattedMessage, injectIntl } from 'react-intl';
import Axios from 'axios';
import { print } from 'graphql';
import { RotateLeftOutlined as OutlineRotate } from '@material-ui/icons';
import StandardButton from '../../../../components/common/StandardButton';

import LOGOUT from '../../../../components/graphQL/mutations/auth/logout.gql';
import DELETE from './delete.gql';
import CommonStyles from '../../../../styles/common';
import { GRAPHQL_URI } from '../../../../graphql/ServerUri';

const httpUri = GRAPHQL_URI;

// Component specific styling
const styles = theme => ({
  ...CommonStyles(theme),
  accountTitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    fontWeight: '400',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  accountSubtitle: {
    marginTop: '1em',
    fontSize: '1.01rem',
    color: theme.palette.disabled,
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  accountDesc: {
    marginTop: '1em',
    fontSize: '1.01rem',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  resetPwd: {
    display: 'flex',
    cursor: 'pointer',
    textDecoration: 'underline',
    marginTop: '1em',
    fontSize: '1.01rem',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
    },
  },
  cssFocused: {},
  paper: {
    marginTop: theme.spacing(3),
    display: 'contents',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'inherit',
    },
    flexDirection: 'column',
    alignItems: 'center',
  },
  outlineRotate: {
    marginRight: '10px',
    marginTop: '3px',
    color: theme.palette.darkBlue,
    cursor: 'pointer',
  },
});

const AccountSecurity = ({ classes, user, client, intl }) => {
  const [open, setOpen] = useState(false);

  const handleDeleteAccount = useCallback(() => {
    console.log('do delete account');

    const deleteUser = () => {
      const result = Axios.post(httpUri, {
        query: print(DELETE),
        variables: { id: user.id },
      })
        .then(resultDelete => {
          console.log(resultDelete);
          setOpen(false);
          if (resultDelete.status === 200) {
            client.resetStore();
            localStorage.clear();
            window.location.href = '/';
          }
        })
        .catch(error => {
          if (error && error.networkError) {
            toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          } else {
            toast.error(intl.formatMessage({ id: 'components.utils.errorHasOccured' }) + error.message, {
              position: 'bottom-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
            });
          }
        });
      return result;
    };
    if (user != null) deleteUser();
  }, [client, user, intl]);

  const handleClickResetPwd = useCallback(() => {
    const logout = () => {
      const result = Axios.post(httpUri, {
        query: print(LOGOUT),
        variables: { all: true },
      }).then(resultMe => {
        console.log(resultMe);
        if (resultMe.status === 200) {
          client.resetStore();
          localStorage.clear();
          window.location.href = '/';
        }
      });
      return result;
    };
    logout();
  }, [client]);

  return (
    <Paper elevation={0} className={classes.cardContainer}>
      <Typography variant="h1" className={classes.accountTitle}>
        <FormattedMessage id="components.myaccount.securityTitle" />
      </Typography>
      <Typography className={classes.accountSubtitle}>
        <FormattedMessage id="components.myaccount.resetPassword" />
      </Typography>
      <Typography className={classes.accountDesc}>
        <FormattedMessage id="components.myaccount.forceResetPassword" />
      </Typography>

      <Typography className={classes.resetPwd} onClick={() => handleClickResetPwd()}>
        <OutlineRotate
          className={classes.outlineRotate}
          fontSize="small"
          onClick={() => {
            console.log('click');
          }}
        />
        <FormattedMessage id="components.myaccount.forceResetPasswordCTA" />
      </Typography>

      <Typography className={classes.accountSubtitle}>
        <FormattedMessage id="components.myaccount.deleteAccount" />
      </Typography>

      <StandardButton
        onClick={() => setOpen(true)}
        customComponentStyling={{
          maxWidth: '80%',
          minWidth: '150px',
          marginBottom: '0',
        }}
      >
        <Typography variant="button">
          <FormattedMessage id="components.myaccount.deleteMyAccount" />
        </Typography>
      </StandardButton>
      <Dialog
        open={open}
        keepMounted
        onBackdropClick={() => setOpen(false)}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          <FormattedMessage id="components.myaccount.deleteMyAccountWarning" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <FormattedMessage id="components.myaccount.deleteMyAccountDesc" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDeleteAccount()} color="primary">
            <FormattedMessage id="components.sessions.joeValidateOK" />
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            <FormattedMessage id="components.sessions.joeValidateCancel" />
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default injectIntl(withStyles(styles)(AccountSecurity));

AccountSecurity.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  intl: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
};
