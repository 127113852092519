import React, { useEffect } from 'react';
import { CenterWrapper, Logo, CircleWrapper, BigText } from './components';

import djangoLogo from '../../images/djangoLogo.png';

const BACKOFFICE_WEBSITE_URL = '/signin';

const Django = () => {
  useEffect(() => {
    window.location.href = BACKOFFICE_WEBSITE_URL;
  });

  return (
    <>
      <CenterWrapper>
        <Logo src={djangoLogo} />
      </CenterWrapper>
      <CircleWrapper>
        <BigText>Veuillez patienter</BigText>
      </CircleWrapper>
    </>
  );
};

export default Django;
