/*
 * HomePage Messages
 *
 * This contains all the text for the Signin / Signup components.
 */
import { defineMessages } from 'react-intl';

export const scope = 'components.session';

export default defineMessages({
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Sign out',
  },
  signIn: {
    id: `${scope}.signIn`,
    defaultMessage: 'Sign in',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Send',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  signInTop_1: {
    id: `${scope}.signInTop_1`,
    defaultMessage: 'Sign',
  },
  signInTop_2: {
    id: `${scope}.signInTop_2`,
    defaultMessage: 'in',
  },
  signUpTop_1: {
    id: `${scope}.signUpTop_1`,
    defaultMessage: 'Create',
  },
  signUpTop_2: {
    id: `${scope}.signUpTop_2`,
    defaultMessage: 'your business account',
  },
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Welcome ',
  },
  createAccountHavePromoCode: {
    id: `${scope}.createAccountHavePromoCode`,
    defaultMessage: 'Have a promo code?',
  },
  createAccountAgreePart1: {
    id: `${scope}.createAccountAgreePart1`,
    defaultMessage: 'By creating an account, you agree to the ',
  },
  createAccountAgreePart3: {
    id: `${scope}.createAccountAgreePart3`,
    defaultMessage: 'and the ',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company Name',
  },
  companyWebsite: {
    id: `${scope}.companyWebsite`,
    defaultMessage: 'Company Website',
  },
  howFunded: {
    id: `${scope}.howFunded`,
    defaultMessage: 'How are you funded?',
  },
  howFundedListItem1: {
    id: `${scope}.howFundedListItem1`,
    defaultMessage: 'self',
  },
  howFundedListItem2: {
    id: `${scope}.howFundedListItem2`,
    defaultMessage: 'seed',
  },
  howFundedListItem3: {
    id: `${scope}.howFundedListItem3`,
    defaultMessage: 'series',
  },
  whatRole: {
    id: `${scope}.whatRole`,
    defaultMessage: "What's your role?",
  },
  whatRoleListItem1: {
    id: `${scope}.whatRoleListItem1`,
    defaultMessage: 'employee',
  },
  whatRoleListItem2: {
    id: `${scope}.whatRoleListItem2`,
    defaultMessage: 'director',
  },
  whatRoleListItem3: {
    id: `${scope}.whatRoleListItem3`,
    defaultMessage: 'executive',
  },
  whatRoleListItem4: {
    id: `${scope}.whatRoleListItem4`,
    defaultMessage: 'founder',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  workEmail: {
    id: `${scope}.workEmail`,
    defaultMessage: 'Work Email',
  },
  workPhone: {
    id: `${scope}.workPhone`,
    defaultMessage: 'Work Phone',
  },
  createAccount: {
    id: `${scope}.createAccount`,
    defaultMessage: 'Create an account',
  },
  emailAddress: {
    id: `${scope}.emailAddress`,
    defaultMessage: 'Email Address',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  passwordForgotten: {
    id: `${scope}.passwordForgotten`,
    defaultMessage: 'Forgot your password?',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  createAccountButton: {
    id: `${scope}.createAccountButton`,
    defaultMessage: 'Create account',
  },
  signupStepOne: {
    id: `${scope}.stepOne`,
    defaultMessage: 'Account Details',
  },
  signupStepTwo: {
    id: `${scope}.stepTwo`,
    defaultMessage: 'Company Details',
  },
  signupStepThree: {
    id: `${scope}.stepThree`,
    defaultMessage: 'Optional Information',
  },
  confirmAccountCreationTitle: {
    id: `${scope}.confirmAccountCreationTitle`,
    defaultMessage: "We've sent you a confirmation email",
  },
  transactions: {
    id: `${scope}.transactions`,
    defaultMessage: 'Transactions',
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Utilisateurs',
  },
  bankAccounts: {
    id: `${scope}.bankAccounts`,
    defaultMessage: 'Comptes Bancaires',
  },
  loans: {
    id: `${scope}.loans`,
    defaultMessage: 'Prêts',
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Paiements',
  },
  messageInApp: {
    id: `${scope}.messageInApp`,
    defaultMessage: 'Message In App',
  },
  myAccount: {
    id: `${scope}.myAccount`,
    defaultMessage: 'My Account',
  },
  myAccountSubtitle: {
    id: `${scope}.myAccountSubtitle`,
    defaultMessage: 'Account detail',
  },
  myAccountSecurity: {
    id: `${scope}.myAccountSecurity`,
    defaultMessage: 'Account security',
  },
  myAccountResetPwd: {
    id: `${scope}.myAccountResetPwd`,
    defaultMessage: 'Reset password',
  },
  myAccountResetPwdLogoutDesc: {
    id: `${scope}.myAccountResetPwdLogoutDesc`,
    defaultMessage: 'To logout and reset your password, click the link below.',
  },
  myAccountResetPwdLogout: {
    id: `${scope}.myAccountResetPwdLogout`,
    defaultMessage: 'Reset Password',
  },
  myAccountSmsNotification: {
    id: `${scope}.myAccountSmsNotification`,
    defaultMessage: 'Notifications',
  },
  myAccountMediaSms: {
    id: `${scope}.myAccountMediaSms`,
    defaultMessage: 'SMS',
  },
  myAccountMediaWhatsapp: {
    id: `${scope}.myAccountMediaWhatsapp`,
    defaultMessage: 'Whatsapp',
  },
  myAccountSmsNotificationAskPhoto: {
    id: `${scope}.myAccountSmsNotificationAskPhoto`,
    defaultMessage: 'Ask for a photo of the receipt on physical transactions',
  },
  myAccountSmsNotificationDeclinedTransac: {
    id: `${scope}.myAccountSmsNotificationDeclinedTransac`,
    defaultMessage: 'Declined transactions',
  },
  myAccountNewsletter: {
    id: `${scope}.myAccountNewsletter`,
    defaultMessage: 'Newsletter',
  },
  myAccountSavePreferences: {
    id: `${scope}.myAccountSavePreferences`,
    defaultMessage: 'Save',
  },
  invoices: {
    id: `${scope}.invoices`,
    defaultMessage: 'Invoices',
  },
  // STEP 4
  signupStepFour: {
    id: `${scope}.signupStepFour`,
    defaultMessage: 'Company',
  },
  signupStepFourHelperText: {
    id: `${scope}.signupStepFourHelperText`,
    defaultMessage: 'We use this information to manage your account and confirm your business identity',
  },
  signupStepFourCompanyInformation: {
    id: `${scope}.signupStepFourCompanyInformation`,
    defaultMessage: 'Company Information',
  },
  signupStepFourCompanyName: {
    id: `${scope}.signupStepFourCompanyName`,
    defaultMessage: 'Company Name',
  },
  signupStepFourRegNumber: {
    id: `${scope}.signupStepFourRegNumber`,
    defaultMessage: 'Legal Registration Number',
  },
  signupStepFourLegalName: {
    id: `${scope}.signupStepFourLegalName`,
    defaultMessage: 'Company Legal Name',
  },
  signupStepFourBusinessAddress: {
    id: `${scope}.signupStepFourBusinessAddress`,
    defaultMessage: 'Business Address',
  },
  signupStepFourEmail: {
    id: `${scope}.signupStepFourEmail`,
    defaultMessage: 'Company Email (must be different than your email)',
  },
  accountBirthdate: {
    id: `${scope}.accountBirthdate`,
    defaultMessage: 'Birthdate',
  },
  accountAddress: {
    id: `${scope}.accountAddress`,
    defaultMessage: 'Address',
  },
  signupStepFourCity: {
    id: `${scope}.signupStepFourCity`,
    defaultMessage: 'City',
  },
  signupStepFourZipCode: {
    id: `${scope}.signupStepFourZipCode`,
    defaultMessage: 'Zip Code',
  },
  signupStepFourCountry: {
    id: `${scope}.signupStepFourCountry`,
    defaultMessage: 'Country',
  },
  signupStepFourCountryListItem1: {
    id: `${scope}.signupStepFourCountryListItem1`,
    defaultMessage: 'France',
  },
  // STEP 6
  signupStepSix: {
    id: `${scope}.signupStepSix`,
    defaultMessage: 'Details',
  },
  signupStepSixHelperText: {
    id: `${scope}.signupStepSixHelperText`,
    defaultMessage: 'We use this information to manage your account and confirm your business identity',
  },
  signupStepSixBusinessDetails: {
    id: `${scope}.signupStepSixBusinessDetails`,
    defaultMessage: 'Business Details',
  },
  signupStepSixVATNumber: {
    id: `${scope}.signupStepSixVATNumber`,
    defaultMessage: 'Legal VAT Number',
  },
  signupStepSixBusinessType: {
    id: `${scope}.signupStepSixBusinessType`,
    defaultMessage: 'Business Type',
  },
  signupStepSixIncorporationYear: {
    id: `${scope}.signupStepSixIncorporationYear`,
    defaultMessage: 'Year of incorporation',
  },
  signupStepSixIncorporationCountry: {
    id: `${scope}.signupStepSixIncorporationCountry`,
    defaultMessage: 'Country of incorporation',
  },
  signupStepSixCompanyActivity: {
    id: `${scope}.signupStepSixCompanyActivity`,
    defaultMessage: 'What is your company activity? What do you sell?',
  },
  businessTypeListItems1: {
    id: `${scope}.businessTypeListItems1`,
    defaultMessage: 'SARL',
  },
  businessTypeListItems2: {
    id: `${scope}.businessTypeListItems2`,
    defaultMessage: 'EURL',
  },
  businessTypeListItems3: {
    id: `${scope}.businessTypeListItems3`,
    defaultMessage: 'SELARL',
  },
  businessTypeListItems4: {
    id: `${scope}.businessTypeListItems4`,
    defaultMessage: 'SA',
  },
  businessTypeListItems5: {
    id: `${scope}.businessTypeListItems5`,
    defaultMessage: 'SAS',
  },
  businessTypeListItems6: {
    id: `${scope}.businessTypeListItems6`,
    defaultMessage: 'SASU',
  },
  businessTypeListItems7: {
    id: `${scope}.businessTypeListItems7`,
    defaultMessage: 'SNC',
  },
  businessTypeListItems8: {
    id: `${scope}.businessTypeListItems8`,
    defaultMessage: 'SC',
  },
  businessTypeListItems9: {
    id: `${scope}.businessTypeListItems9`,
    defaultMessage: 'Association',
  },
  businessTypeListItems10: {
    id: `${scope}.businessTypeListItems10`,
    defaultMessage: 'Autre',
  },
  incorporationCountryListItems1: {
    id: `${scope}.incorporationCountryListItems1`,
    defaultMessage: 'France',
  },
  // STEP 7
  signupStepSeven: {
    id: `${scope}.signupStepSeven`,
    defaultMessage: '25%+ Owners',
  },
  signupStepSevenHelperText: {
    id: `${scope}.signupStepSevenHelperText`,
    defaultMessage:
      'To comply with regulation, we collect details for each legal entity who owns 25% or more of your company. If no legal entity owns more than 25% or more of your company, you may skip this section',
  },
  signupStepSevenBusinessOwnerShip: {
    id: `${scope}.signupStepSevenBusinessOwnerShip`,
    defaultMessage: 'Business Ownership',
  },
  signupStepSevenNobody: {
    id: `${scope}.signupStepSevenNobody`,
    defaultMessage: 'Nobody',
  },
  signupStepSevenOwnsMore: {
    id: `${scope}.signupStepSevenOwnsMore`,
    defaultMessage: 'owns more than 25% of the company',
  },
  signupStepSevenSkip: {
    id: `${scope}.signupStepSevenSkip`,
    defaultMessage: '(skip)',
  },
  signupStepSevenIndividualName: {
    id: `${scope}.signupStepSevenIndividualName`,
    defaultMessage: 'Individual Name',
  },
  signupStepSevenPhoneNumber: {
    id: `${scope}.signupStepSevenPhoneNumber`,
    defaultMessage: 'Phone Number',
  },
  signupStepSevenAddOwner: {
    id: `${scope}.signupStepSevenAddOwner`,
    defaultMessage: 'Add Owner',
  },
  signupStepSevenLinkBank: {
    id: `${scope}.signupStepSevenLinkBank`,
    defaultMessage: 'Link a bank account',
  },
  signupStepSevenLinkBankLater: {
    id: `${scope}.signupStepSevenLinkLater`,
    defaultMessage: 'Link later',
  },
  // STEP 8
  signupStepEight: {
    id: `${scope}.signupStepEight`,
    defaultMessage: 'Bank Account',
  },
  signupStepEightHelperText: {
    id: `${scope}.signupStepEightHelperText`,
    defaultMessage:
      'We will ask you to link your bank account to determine your monthly limit. We do not store any personal information, this operation is totally safe.',
  },
  signupStepEightSelectBank: {
    id: `${scope}.signupStepEightSelectBank`,
    defaultMessage: 'Select your bank',
  },
  signupStepEightBankLogin: {
    id: `${scope}.signupStepEightBankLogin`,
    defaultMessage: 'Login to your bank account',
  },
  signupStepEightBankLoginHelperText1: {
    id: `${scope}.signupStepEightBankLoginHelperText`,
    defaultMessage: 'Please enter your',
  },
  signupStepEightBankLoginHelperText2: {
    id: `${scope}.signupStepEightBankLoginHelperText2`,
    defaultMessage: 'credentials',
  },
  signupStepEightID: {
    id: `${scope}.signupStepEightID`,
    defaultMessage: 'UserID',
  },
  signupStepEightPassword: {
    id: `${scope}.signupStepEightPassword`,
    defaultMessage: 'Password',
  },
  signupStepEightLogin: {
    id: `${scope}.signupStepEightLogin`,
    defaultMessage: 'Login',
  },
  signupStepEightSecurityQuestion: {
    id: `${scope}.signupStepEightSecurityQuestion`,
    defaultMessage: 'Security question',
  },
  signupStepEightSecurityQuestionHelperText: {
    id: `${scope}.signupStepEightSecurityQuestionHelperText`,
    defaultMessage: 'Select an option to receive your Secure Activation Code',
  },
  signupStepEightSecurityQuestionOptionText: {
    id: `${scope}.signupStepEightSecurityQuestionOptionText`,
    defaultMessage: 'Text',
  },
  signupStepEightSecurityQuestionOptionEmail: {
    id: `${scope}.signupStepEightSecurityQuestionOptionEmail`,
    defaultMessage: 'Email',
  },
  signupStepEightSecurityQuestionGetActivation: {
    id: `${scope}.signupStepEightSecurityQuestionGetActivation`,
    defaultMessage: 'Get activation code',
  },
  signupStepEightSecurityQuestionMFAChallengeExpired1: {
    id: `${scope}.signupStepEightSecurityQuestionMFAChallengeExpired1`,
    defaultMessage: 'Your MFA challenge has expired. Try again by',
  },
  signupStepEightSecurityQuestionMFAChallengeExpired2: {
    id: `${scope}.signupStepEightSecurityQuestionMFAChallengeExpired2`,
    defaultMessage: 'clicking here',
  },
  signupStepEightSecurityQuestionConnectLater: {
    id: `${scope}.signupStepEightSecurityQuestionConnectLater`,
    defaultMessage: 'Connect later',
  },
  signupStepEightSecurityQuestionCodeReceived: {
    id: `${scope}.signupStepEightSecurityQuestionCodeReceived`,
    defaultMessage: 'Enter the secure activation code you received',
  },
  signupStepEightSecureActivationCode: {
    id: `${scope}.signupStepEightSecureActivationCode`,
    defaultMessage: 'Secure Activation Code',
  },
  signupStepEightLinkAccount: {
    id: `${scope}.signupStepEightLinkAccount`,
    defaultMessage: 'Link Account',
  },
  signupStepEightLinkAccountButton: {
    id: `${scope}.signupStepEightLinkAccountButton`,
    defaultMessage: 'Link this account',
  },
  signupStepEightHelperText2: {
    id: `${scope}.signupStepEightHelperText2`,
    defaultMessage:
      'To comply with regulation, we collect the details of each legal entity who owns 25% or more of your company. If no legal entity owns 25% or more of your company, you may skip this section',
  },
  signupStepEightYourBankAccount: {
    id: `${scope}.signupStepEightYourBankAccount`,
    defaultMessage: 'Your bank account',
  },
  signupStepEightYourBankAccountSubTitle: {
    id: `${scope}.signupStepEightYourBankAccountSubTitle`,
    defaultMessage: 'Your International Bank Account Number (IBAN)',
  },
  signupStepEightBIC: {
    id: `${scope}.signupStepEightBIC`,
    defaultMessage: 'Bank Identifier Code (BIC)',
  },
  signupStepEightCheckAccount: {
    id: `${scope}.signupStepEightCheckAccount`,
    defaultMessage: 'Check Bank Account',
  },
  signupStepEightGetIbanError: {
    id: `${scope}.signupStepEightGetIbanError`,
    defaultMessage:
      "There was an error accessing your bank account's information, please check your credentials and try again.",
  },
  // STEP 9
  signupStepNine: {
    id: `${scope}.signupStepNine`,
    defaultMessage: 'Review',
  },
  signupStepNineHelperText1: {
    id: `${scope}.signupStepNineHelperText1`,
    defaultMessage:
      'Please carefully review all the following details. Please note it can take several minutes before your submission is processed.',
  },
  signupStepNineHelperText2: {
    id: `${scope}.signupStepNineHelperText2`,
    defaultMessage: 'Once you have submitted this form, you will not be able to make any changes.',
  },
  signupStepNineOwner: {
    id: `${scope}.signupStepNineOwner`,
    defaultMessage: 'Owner',
  },
  signupStepNineBusinessBankAccount: {
    id: `${scope}.signupStepNineBusinessBankAccount`,
    defaultMessage: 'Business bank account',
  },
  signupStepNineBank: {
    id: `${scope}.signupStepNineBank`,
    defaultMessage: 'Bank statement',
  },
  signupStepNineAccount: {
    id: `${scope}.signupStepNineAccount`,
    defaultMessage: 'Account',
  },
  signupStepNineCompanyDescription: {
    id: `${scope}.signupStepNineCompanyDescription`,
    defaultMessage: 'Company Description',
  },
  signupStepNineBankLinked: {
    id: `${scope}.signupStepNineBankLinked`,
    defaultMessage: 'Your bank account is linked',
  },
  signupStepNineBankNotLinked: {
    id: `${scope}.signupStepNineBankNotLinked`,
    defaultMessage: 'Your bank account is not linked',
  },
  signupStepNineLegalAgreement: {
    id: `${scope}.signupStepNineLegalAgreement`,
    defaultMessage: 'Legal agreements',
  },
  signupStepNineSubmitApplication: {
    id: `${scope}.signupStepNineSubmitApplication`,
    defaultMessage: 'Submit application',
  },
  signupStepNineNoControllingOfficer: {
    id: `${scope}.signupStepNineNoControllingOfficer`,
    defaultMessage: 'You are not one of the controlling officer of your company',
  },
  signupStepNineNoOwner: {
    id: `${scope}.signupStepNineNoOwner`,
    defaultMessage: 'You did not declare any 25%+ owners for your company',
  },
  finalizedAccountCreationDialog: {
    id: `${scope}.finalizedAccountCreationDialog`,
    defaultMessage:
      'Your account has been created successfully. You will now be redirected to the login page.',
  },
  pleaseWait: {
    id: `${scope}.pleaseWait`,
    defaultMessage: 'Please wait while your account is being created',
  },
  problemFinalizingAccountCreationDialog: {
    id: `${scope}.problemFinalizingAccountCreationDialog`,
    defaultMessage: 'There was a problem creating your account. Please try again later.',
  },
  problemFinalizingAccoutCreationTitle: {
    id: `${scope}.problemFinalizingAccoutCreationTitle`,
    defaultMessage: 'Account creation error',
  },
});
