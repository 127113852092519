import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  description: {
    fontFamily: 'Lato',
    fontSize: '23px',
    fontWeight: 500,
    color: '#081433',
    lineHeight: '27.6px',
    textAlign: 'center',
    marginBottom: '16px',
    paddingTop: '14px',
  },
}));

export const DynamicBoldText = ({ text, boldText }) => {
  const classes = useStyles();
  const lines = text?.split(/<br\s*\/?>/gi);

  return (
    <>
      {lines.map((line, lineIndex) => {
        const parts = line.split(new RegExp(`(${boldText})`, 'gi'));
        return (
          <p key={lineIndex} className={classes.description}>
            {parts.map((part, index) =>
              part?.toLowerCase() === boldText?.toLowerCase() ? (
                <strong key={index}>**** {part}</strong>
              ) : (
                part
              ),
            )}
          </p>
        );
      })}
    </>
  );
};
