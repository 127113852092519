import { Chip, TableCell } from '@material-ui/core';
import { CheckCircle, Cancel, Schedule } from '@material-ui/icons';
export const DescriptionCell = ({ status }) => {
  let icon;
  switch (status) {
    case 'not_registered': {
      icon = (
        <Chip
          style={{ backgroundColor: '#BDFFBC', color: '#10C001' }}
          icon={<CheckCircle width={10} height={10} style={{ color: '#10C001' }} />}
          label="Non Fiché"
        ></Chip>
      );
      break;
    }
    case 'registered': {
      icon = (
        <Chip
          style={{ backgroundColor: '#FFBCBC', color: '#FF0000' }}
          icon={<Cancel width={10} height={10} style={{ color: '#FF0000' }} />}
          label="Fiché"
        ></Chip>
      );
      break;
    }
    case 'call_failed': {
      icon = (
        <Chip
          style={{ backgroundColor: '#FFBCBC', color: '#FF0000' }}
          icon={<Cancel width={10} height={10} style={{ color: '#FF0000' }} />}
          label="Echec interrogation"
        ></Chip>
      );
      break;
    }
    case 'homonymy': {
      icon = (
        <Chip
          style={{ backgroundColor: '#FFD0BC', color: '#FF6B00' }}
          icon={<Cancel width={10} height={10} style={{ color: '#FF6B00' }} />}
          label="Autre personne fichée"
        ></Chip>
      );
      break;
    }
    case 'on_hold': {
      icon = (
        <Chip
          style={{ backgroundColor: '#BCDBFF', color: '#0075FF' }}
          icon={<Schedule width={10} height={10} style={{ color: '#0075FF' }} />}
          label="En attente de réponse"
        ></Chip>
      );
      break;
    }
    default:
  }
  if (!icon) return <TableCell>{status}</TableCell>;
  return <TableCell>Statut FICP actualisé : {icon}</TableCell>;
};
