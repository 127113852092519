import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';

import ComponentLoading from '../components/common/ComponentLoading';

import GET_FULL_LINK_QUERY from './urlShorten.gql';
import { FormattedMessage } from 'react-intl';

const ShortLinkRedirect = ({ hash }) => {
  return (
    <Query query={GET_FULL_LINK_QUERY} variables={{ code: hash }}>
      {({ loading, error, data }) => {
        if (loading) return <ComponentLoading />;
        if (error) {
          if (error.networkError) {
            return <FormattedMessage id="components.utils.networkError"></FormattedMessage>;
          }
          return <FormattedMessage id="components.utils.errorHasOccured"></FormattedMessage>;
        }
        if (data && data.urlShorten) window.location = data.urlShorten;
        else {
          return <FormattedMessage id="components.utils.noHashCode"></FormattedMessage>;
        }
        return null;
      }}
    </Query>
  );
};

ShortLinkRedirect.propTypes = {
  hash: PropTypes.string,
};

export default ShortLinkRedirect;
