/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

// Material UI imports
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondaryBackground,
  },
  gutterLeft: {
    paddingLeft: '1em',
    paddingRight: '2em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
  },
  gutterMiddleAndRight: {
    paddingRight: '1em',
    paddingLeft: '2em',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
  },
  spaceForBurgerMenu: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '14%',
    },
  },
  margin: {
    width: '100%',
    minHeight: '50vh',
  },
});

const handleLeftPanel = (windowSize, panelStyle) => {
  if (panelStyle === 'largerRightSide' && windowSize.innerWidth > 599 && windowSize.innerWidth < 1280) {
    return { paddingBottom: '0em', paddingRight: '2em' };
  }
  if (windowSize.innerWidth > 599 && windowSize.innerWidth < 1280) {
    return { paddingRight: '2em' };
  }
  if (windowSize.innerWidth > 1279) {
    return { paddingBottom: '0em' };
  }
  if (panelStyle === 'largerRightSide') {
    return { paddingBottom: '0em' };
  }
  return null;
};

const handleRightPanel = (windowSize, panelStyle) => {
  if (panelStyle === 'largerRightSide' && windowSize.innerWidth < 600) {
    return { paddingBottom: '-0.85em' };
  }

  if (panelStyle === 'largerRightSide' && windowSize.innerWidth > 599 && windowSize.innerWidth < 960) {
    return { paddingRight: '2em' };
  }

  if (panelStyle === 'largerRightSide') {
    return { paddingBottom: '-0.85em' };
  }
  if (panelStyle === 'largerLeftSide') {
    return { paddingBottom: '8em' };
  }
  return null;
};

const handlePanelLayout = props => {
  const { classes, windowSize, leftPanel, rightPanel, fullPanel, panelStyle, standalone } = props;

  // panelStyle options:
  // largerLeftSide = LEFT PANEL 2/3 - RIGHT PANEL 1/3
  // largerRightSide = LEFT PANEL 1/3 - RIGHT PANEL 2/3
  // fullPanel = PANEL 3/3

  const panelContents = [
    {
      id: 'left',
      content: (
        <Grid
          item
          xs={12}
          sm={12}
          md={windowSize.innerWidth > 959 && windowSize.innerWidth < 1368 ? 12 : 12}
          lg={windowSize.innerWidth > 1279 && windowSize.innerWidth < 1681 ? 8 : 7}
          xl={7}
          className={panelStyle === 'largerLeftSide' ? classes.gutterMiddleAndRight : classes.gutterLeft}
          style={handleLeftPanel(windowSize, panelStyle)}
          key="leftPanel"
        >
          {leftPanel.map(card => card.component)}
        </Grid>
      ),
    },
    {
      id: 'right',
      content: (
        <Grid
          item
          xs={12}
          sm={12}
          md={windowSize.innerWidth > 959 && windowSize.innerWidth < 1368 ? 12 : 12}
          lg={windowSize.innerWidth > 1279 && windowSize.innerWidth < 1681 ? 4 : 4}
          xl={4}
          className={panelStyle !== 'largerLeftSide' ? classes.gutterMiddleAndRight : classes.gutterLeft}
          style={handleRightPanel(windowSize, panelStyle)}
          key="rightPanel"
        >
          {rightPanel.map(card => card.component)}
        </Grid>
      ),
    },
  ];

  const fullScreenPanelStandalone = [
    {
      id: 'fullStandalone',
      content: (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={windowSize.innerWidth > 1279 && windowSize.innerWidth < 1681 ? 12 : 11}
          style={{
            paddingLeft: '2em',
            paddingRight: '2em',
            paddingBottom: '1em',
          }}
          key="fullStandalone"
        >
          {fullPanel.map(card => card.component)}
        </Grid>
      ),
    },
  ];

  const fullScreenPanel = [
    {
      id: 'fullPanel',
      content: (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={11}
          style={
            windowSize.innerWidth < 600
              ? {
                  paddingLeft: '1em',
                  paddingRight: '1em',
                  paddingBottom: '0.85em',
                  marginTop: '-4.75em',
                }
              : {
                  paddingLeft: '1.5em',
                  paddingRight: '1.5em',
                  paddingBottom: '0.85em',
                  marginTop: '-0.85em',
                }
          }
          key="fullPanel"
        >
          {fullPanel.map(card => card.component)}
        </Grid>
      ),
    },
  ];

  if (panelStyle === 'largerLeftSide') {
    return panelContents.map(panel => panel.content);
  }
  if (panelStyle === 'largerRightSide') {
    return panelContents.reverse().map(panel => panel.content);
  }
  if (panelStyle === 'fullPanel' && standalone) {
    return fullScreenPanelStandalone.map(panel => panel.content);
  }
  if (panelStyle === 'fullPanel' && !standalone) {
    return fullScreenPanel.map(panel => panel.content);
  }
  return null;
};

const DashboardContainer = props => {
  const { classes, noMargin, backgroundColor } = props;

  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: backgroundColor,
        paddingBottom: !noMargin ? 100 : 0,
      }}
    >
      <Grid container direction="row" className={classes.spaceForBurgerMenu}>
        {handlePanelLayout(props)}
      </Grid>
    </div>
  );
};

DashboardContainer.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  windowSize: PropTypes.object.isRequired,
  leftPanel: PropTypes.array,
  rightPanel: PropTypes.array,
  fullPanel: PropTypes.array,
  panelStyle: PropTypes.string.isRequired,
  standalone: PropTypes.bool,
  noMargin: PropTypes.bool,
  displayWarning: PropTypes.bool,
};

DashboardContainer.defaultProps = {
  leftPanel: [],
  rightPanel: [],
  fullPanel: [],
  standalone: false,
  displayWarning: true,
};

export default withStyles(styles)(DashboardContainer);
